import {themeComplement} from '../../AppAdmin/css/theme';

export default theme => ({
  cardContainer: {
    transitionDuration: '0.15s',
  },
  card: {
    textAlign: 'left',
    transition: 'box-shadow 0.1s ease-in-out',
    '&:hover': {
      boxShadow: theme.shadows[4],
      '& #mediaIcon': {
        fontSize: '5.3em',
      },
      '& #mediaIconBackground': {
        fontSize: '20em',
      },
    },
    '& #mediaIcon': {
      transition: 'all .2s linear',
    },
    '& #mediaIconBackground': {
      transition: 'all .2s linear',
    }
  },
  cardHeader: {
    padding: theme.spacing.unit,
    '&:hover': {
      '& $editButton': {
        opacity: 1
      }
    }
  },
  editButton: {
    color: 'rgba(0, 0, 0, 0.13)',
    width: '38px',
    height: '38px',
    float: 'right',
    opacity: 0,
    '& svg': {
      width: '0.9em',
      height: '0.9em',
      // marginTop: '-4px',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  number: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px 4px 50% 4px',
    width: '30px',
    height: '30px',
    fontSize: '1rem',
  },
  actions: {
    position: 'relative',
    '& button': {
      color: 'rgba(0, 0, 0, 0.13)',
      width: '38px',
      height: '38px'
    },
    '& svg': {
      width: '0.9em',
      height: '0.9em',
      // marginTop: '-4px',
    }
  },
  deleteIcon: {
    position: 'absolute',
    right: '12px',
    color: 'rgba(0, 0, 0, 0.13)',
    '&:hover': {
      color: themeComplement.palette.notifications.error.color
    }
  },
  deleteFileTitle: {
    color: theme.palette.primary.main
  },
  emptyZone: {
    border: '4px solid #80808026',
    borderStyle: 'dashed',
    padding: '8em',
    borderRadius: '10px',
  },
  emptyZoneIcon: {
    fontSize: '14em',
    color: '#80808026'
  },
  emptyZoneText: {
    color: '#808080'
  },
  media: {
    height: 0,
    paddingTop: '30%',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  mediaIcon: {
    position: 'absolute',
    top: '0.05em',
    right: '0.2em',
    fontSize: '5em',
  },
  mediaIconBackground: {
    position: 'absolute',
    top: '-50%',
    left: 0,
    fontSize: '17em',
  },
  mediaArchiveIcon: {
    color: '#ffeb3b7d',
  },
  mediaArchiveIconBackground: {
    color: '#ffeb3b2b',
  },
  mediaPhotoIcon: {
    color: '#2074d070',
  },
  mediaPhotoIconBackground: {
    color: '#2074d00d',
  },
  mediaMovieIcon: {
    color: '#71d02070',
  },
  mediaMovieIconBackground: {
    color: '#71d02012',
  },
  mediaDefaultIcon: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  mediaDefaultIconBackground: {
    color: 'rgba(0, 0, 0, 0.04)',
  },
  mediaPdfIcon: {
    color: '#d03c2070',
  },
  mediaPdfIconBackground: {
    color: '#d03c200f',
  },


  btnDropZone: {
    float: 'right',
  },
  uploadIcon: {
    marginRight: theme.spacing.unit,
  },
  dropZone: {
    transitionDuration: '0.15s',
  },
  dropZoneAccept: {
    '& $dropZoneAcceptPanel': {
      display: 'block',
    },
    '& $cardContainer': {
      opacity: '0.1',
    },
  },
  dropZoneReject: {
    pointer: 'not-allowed',
    '& $dropZoneRejectPanel': {
      display: 'block',
    },
    '& $dropZoneAcceptPanel': {
      display: 'none',
    },
    '& $cardContainer': {
      opacity: '0.1',
    },
  },
  dropZoneAcceptPanel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    border: '4px solid #80808026',
    borderStyle: 'dashed',
    padding: '8em',
    borderRadius: '10px',
    margin: '12px 12px 0 12px'
  },
  AcceptPanelDisplayNone: {
    display: 'none',
  },
  AcceptPanelDisplay: {
    display: 'block',
  },
  dropZoneAcceptPanelIcon: {
    paddingTop: '0.2em',
    fontSize: '14em',
    color: '#80808046'
  },
  dropZoneAcceptPanelText: {
    color: '#808080',
  },

  dropZoneRejectPanel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    border: '4px solid #80808026',
    borderStyle: 'dashed',
    padding: '8em',
    borderRadius: '10px',
    display: 'none',
    margin: '12px 12px 0 12px'
  },
  dropZoneRejectPanelIcon: {
    paddingTop: '0.2em',
    fontSize: '14em',
    color: '#d67a7a46'
  },
  dropZoneRejectPanelText: {
    color: themeComplement.palette.notifications.error.color,
  },
});
