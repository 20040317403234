import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ProgrammeCss from './css/ProgrammeCss';
import {Cell, DataTable} from '@oniti/datatable-material';
import connect from "react-redux/es/connect/connect";
import Search from '@material-ui/icons/Search';
import {collectionActions} from "../../../reducers/collectionsReducer";
import DetailLotsModal from "./DetailLotsModal";
import Dropzone from 'react-dropzone';
import EmptyDocumentsIcon from '@material-ui/icons/AddToPhotos';
import BlockIcon from '@material-ui/icons/RemoveCircle';
import {importLots} from './actions/LotsActions';
import CloudUpload from '@material-ui/icons/CloudUpload';
import FileIcon from '@material-ui/icons/AttachFile';
import Documents from "../Document";
import CloseIcon from '@material-ui/icons/Close';

class DetailLots extends Component {
  dropzoneRef = {};

  state = {
    openDetailModal: false,
    openDocumentsModal: false,
    selectedLot: {}
  };

  /**
   * Rendu de la cellule des actions
   * @param  {[type]} lot [description]
   * @return {[type]}       [description]
   */
  getActionsButtons(lot) {
    const {classes} = this.props;

    return (
      <div>
        <IconButton
          aria-label="Edit"
          key="edit"
          color="primary"
          onClick={this.onClickViewHandler.bind(this, lot)}
          className={classes.lotsActionButtons + ' pullRight'}
          title="Détail du lot"
        >
          <Search/>
        </IconButton>
        <IconButton
          aria-label="Documents"
          key="documents"
          color="primary"
          onClick={this.onClickDocumentsHandler.bind(this, lot)}
          className={classes.lotsActionButtons + ' pullRight'}
          title="Gestion des documents"
        >
          <FileIcon/>
        </IconButton>
      </div>
    );
  }

  /**
   * Rendu du bouton d'import des lots
   * @return {[type]} [description]
   */
  getImportButton() {
    const {classes} = this.props;

    return (
      <Button
        title='Importer les lots'
        color='primary'
        variant="contained"
        className={classes.btnDropZone}
        onClick={() => {
          this.dropzoneRef.open()
        }}
      >
        <CloudUpload className={classes.uploadIcon}/> Importer les lots
      </Button>
    );
  }

  /**
   * Gère le drop de fichiers sur la zone
   * @param  {[type]} files
   * @return {[type]}       [description]
   */
  onDropAccepted(files) {
    let data = new FormData();
    data.append('file', files[0]); // multiple = false, donc pas de doute ;)
    importLots(this.props.dispatch, this.props.programmeStore.detail.uuid, data)
  }

  /**
   * Gère le changement de statut dans le select
   * @param event
   */
  changeStatutHandler = event => {
    const {dispatch, lotsStore} = this.props;
    let lot = lotsStore.list.find(lot => {
        return lot.uuid === event.target.name
      }),
      data = {
        ...lot,
        statut: event.target.value
      };

    collectionActions(dispatch, 'lots', 'UPDATE', data);
  };

  /**
   * Génère la select Box
   * @param lot
   * @returns {*}
   */
  getStatutSelect(lot) {
    const statuts = [
      {value: 'disponible', label: 'Disponible'},
      {value: 'option', label: 'Option'},
      {value: 'en_cours_signature', label: 'En cours de signature'},
      {value: 'reserve', label: 'Réservé'},
    ];
    let selectChoices = [];

    selectChoices.push(
      Object.keys(statuts).map(key => {
        let statut = statuts[key];

        return <MenuItem key={key} value={statut.value}>{statut.label}</MenuItem>
      })
    );

    return <Select
      value={lot.statut}
      onChange={this.changeStatutHandler}
      inputProps={{name: lot.uuid}}
      disableUnderline
      fullWidth
    >
      {selectChoices}
    </Select>
  }

  /**
   * Handler sur edit
   * @param  {[type]} lot [description]
   * @return {[type]}      [description]
   */
  onClickViewHandler(lot) {
    this.setState({
      openDetailModal: true,
      selectedLot: lot
    })
  }

  closeModalHandler() {
    this.setState({
      openDetailModal: false,
      selectedLot: {}
    })
  }

  /**
   * Handler sur edit
   * @param  {[type]} lot [description]
   * @return {[type]}      [description]
   */
  onClickDocumentsHandler(lot) {
    const {dispatch} = this.props;

    this.setState({
      openDocumentsModal: true,
      selectedLot: lot
    }, () => {
      collectionActions(dispatch, 'ressources', 'INDEX', {params: {item_uuid: lot.uuid, ressource_type: 'document'}})
    })
  }

  closeDocumentsModalHandler(lot) {
    const {dispatch} = this.props;
    this.setState({
      openDocumentsModal: false,
      selectedLot: {}
    }, () => {
      collectionActions(dispatch, 'ressources', 'INDEX', {params: {item_uuid: lot.programme_uuid}})
    })
  }

  /**
   * Retourne les Cells du datatable
   * @return {[type]} [description]
   */
  getCells() {
    const cellsConfig = [
      {
        datakey: 'num',
        title: 'Numéro de lot',
        sortable: true,
        searchable: true,
      },
      {
        datakey: 'type_bien',
        title: 'Type de bien',
        sortable: true,
        searchable: true,
      },
      {
        datakey: 'type',
        title: 'Type',
        sortable: true,
        searchable: true,
      },
    ];
    let cells = cellsConfig.map((conf, index) => <Cell key={index} {...conf} />);

    cells.push(
      <Cell
        key="statut"
        title="Statut"
        format={this.getStatutSelect.bind(this)}
        sortable={false}
      />
    );

    cells.push(
      <Cell
        key="crud"
        format={this.getActionsButtons.bind(this)}
        sortable={false}
      />
    );

    return cells;
  }

  getDocumentsModal() {
    let {selectedLot} = this.state;
    const {classes} = this.props;

    return (
      <Dialog
        open={this.state.openDocumentsModal}
        onClose={this.closeDocumentsModalHandler.bind(this, selectedLot)}
        fullScreen
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={this.closeDocumentsModalHandler.bind(this, selectedLot)}
                        aria-label="Close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="title" color="inherit" className={classes.flex}>
              Gestion des documents associés au lot : {selectedLot.num}
            </Typography>
            <Button onClick={this.closeDocumentsModalHandler.bind(this, selectedLot)} color="primary">
              Fermer
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <Documents
            itemUuid={selectedLot.uuid}
            itemType='lot'
            showFavoriteOnCards={false}
          />
        </DialogContent>
      </Dialog>
    )
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    let {classes, lotsStore} = this.props;
    if (!lotsStore.list) return <CircularProgress/>;

    return (
      <Dropzone
        ref={(node) => {
          this.dropzoneRef = node;
        }}
        multiple={false}
        disableClick={true}
        disablePreview={true}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/wps-office.xlsx"
        style={{}}
        className={classes.dropZone}
        acceptClassName={classes.dropZoneAccept}
        rejectClassName={classes.dropZoneReject}
        activeClassName="dropActive"
        onDropAccepted={this.onDropAccepted.bind(this)}
        onDropRejected={(a, b) => {
          console.log(a, b)
        }} // // debug rejected files
      >
        <Grid
          container
          spacing={24}
          className={classes.programmeContainer}
        >
          <Grid item xs={12} className={classes.dropZoneAcceptPanel}>
            <EmptyDocumentsIcon className={classes.dropZoneAcceptPanelIcon}/>
            <Typography className={classes.dropZoneAcceptPanelText}>Déposez votre fichier ici afin de
              l’ajouter.</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dropZoneRejectPanel}>
            <BlockIcon className={classes.dropZoneRejectPanelIcon}/>
            <Typography className={classes.dropZoneRejectPanelText}>Impossible d’importer ce type de
              document.</Typography>
          </Grid>
          <Grid item xs={12}>
            {this.getImportButton()}
          </Grid>
          <Grid item xs={12}>
            <DataTable
              showPagination={false}
              datas={lotsStore.list}
              className={classes.dataTableLots}
            >
              {this.getCells()}
            </DataTable>
          </Grid>
          <DetailLotsModal
            openModal={this.state.openDetailModal}
            closeModal={this.closeModalHandler.bind(this)}
            lot={this.state.selectedLot}
          />
          {this.getDocumentsModal()}
        </Grid>
      </Dropzone>
    )
  }
}

DetailLots = withStyles(ProgrammeCss)(DetailLots);

DetailLots = connect((store) => {
  return {
    lotsStore: store.collections.lots,
    programmeStore: store.collections.programmes,
  }
})(DetailLots);

export default DetailLots
