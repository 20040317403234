import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles/index";
import {withRouter} from "react-router-dom";
import StatCardCss from "./css/StatCardCss";
import {Button, Card, CardActions, CardContent, Grid, Grow, Typography} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';

/**
 * Le component attends un objet du type
 * utilisateurs: {
          label: 'Utilisateurs',
          color: '#605ca8',
          number: '123',
          link: '/users',
          icon: <PeopleIcon/>
        }
 */
class StatCard extends Component {
  /**
   * Gère le click sur le lien en bas de la Card
   * @param link
   */
  onClickStatHandler(link) {
    this.props.history.push(link)
  }

  /**
   * Rendu global du component
   * @returns {*}
   */
  render() {
    const {classes} = this.props;
    let stat = this.props.stat;

    return (
      <Grow in={true}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
          <Card className={classes.statCard} style={{backgroundColor: stat.color}}>
            <CardContent className={classes.statCardContent}>
              <Typography variant="display1" gutterBottom className={classes.statNumber}>
                {stat.value}
              </Typography>
              <Typography gutterBottom className={classes.statLabel}>
                {stat.label}
              </Typography>
              <div className={classes.statIconContainer}>
                {stat.icon}
              </div>
            </CardContent>
            <CardActions className={classes.statCardActions}>
              <Button
                size="small"
                className={classes.statButton}
                onClick={this.onClickStatHandler.bind(this, stat.link)}
              >
                Plus d’infos <ChevronRight/>
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grow>
    )
  }
}

StatCard.propTypes = {
  stat: PropTypes.object.isRequired
};

StatCard = withStyles(StatCardCss)(StatCard);
StatCard = withRouter(StatCard);

export default StatCard
