import {forceRedirectLogin} from './authReducer';
import {themeComplement} from "../components/admin/AppAdmin/css/theme";

/**
 * ###########################################################################
 * ##########################  FUNCTIONS #####################################
 * ###########################################################################
 */

export function clearNotification(dispatch) {
  dispatch({type: 'RESET_NOTIFICATION'})
}

export function addNotification(dispatch, options) {
  dispatch({payload: options, type: 'ADD_NOTIFICATION'})
}

export function axiosErrorHandler(dispatch, error) {
  if (error.response) {
    if (error.response.status === 401) {
      forceRedirectLogin(dispatch);
      addNotification(dispatch, {
        message: "Votre session a expiré",
        bgColor: themeComplement.palette.notifications.error.color
      })
    }
    else if (error.response.data && error.response.data.error) {
      addNotification(dispatch, {
        message: error.response.data.error,
        bgColor: themeComplement.palette.notifications.error.color
      })
    } else if (error.response.data && error.response.data.errors) {
      let errors = Object.values(error.response.data.errors).map(arr => {
        return arr.join(', ');
      }).join(', ');
      addNotification(dispatch, {
        message: errors,
        bgColor: themeComplement.palette.notifications.error.color
      })
    } else if (error.response.status === 500) {
      addNotification(dispatch, {
        message: "Une erreur est survenue",
        bgColor: themeComplement.palette.notifications.error.color
      })
    }
  } else {
    addNotification(dispatch, {
      message: "Une erreur est survenue",
      bgColor: themeComplement.palette.notifications.error.color
    })
  }
}

/**
 * ###########################################################################
 * ##########################  REDUCER   #####################################
 * ###########################################################################
 */

let initialState = {
  message: null,
  bgColor: null,
  cancelCallback: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION': {
      return {...state, ...action.payload}
    }
    case 'RESET_NOTIFICATION': {
      return {...initialState}
    }
    default:
      return state
  }
}
