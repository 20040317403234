import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import EmptyDocumentsIcon from '@material-ui/icons/AddToPhotos';
import CloudUpload from '@material-ui/icons/CloudUpload';
import BlockIcon from "@material-ui/icons/RemoveCircle";
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Dropzone from "react-dropzone";
import connect from "react-redux/es/connect/connect";
import {collectionActions} from "../../../reducers/collectionsReducer";
import DocumentCss from './css/DocumentCss';
import DocumentCard from "./DocumentCard";
import {importDocuments} from "./actions/DocumentsActions";

class Documents extends Component {
  dropzoneRef = {};
  dropZoneHeight = window.innerHeight - 64 - 72 - 48 - 64; //TODO Voir pour gérer le changement sur l'event "fullscreenchange"
  mimeTypeConfig = [
    'application/zip',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'text/plain',
    'image/png',
    'image/jpg',
    'image/gif',
    'image/jpeg',
    'video/mpeg',
    'video/x-ms-wmv',
    'video/mp4',
    'video/x-msvideo',
  ];

  state = {
    openModal: false,
    selectedRessource: null,
    action: null,
    enableDropzone: true,
  };

  /**
   * Gère le click sur les icones public, pro et favorite
   * @param button
   * @param ressource
   */
  onClickIconHandler(button, ressource) {
    const {dispatch} = this.props;
    let data = {};

    switch (button) {
      case 'public':
        data = {...ressource, diffusion: ressource.diffusion !== 'public' ? 'public' : 'aucune'};
        break;
      case 'pro':
        data = {...ressource, diffusion: ressource.diffusion !== 'pro' ? 'pro' : 'aucune'};
        break;
      default:
        data = {...ressource, favorite: !ressource.favorite};
        break;
    }

    collectionActions(dispatch, 'ressources', 'UPDATE', data);
  }

  /**
   * Gère l'appel de la modale en mode suppression
   * @param ressource
   */
  onClickDeleteIconHandler(ressource) {
    this.setState({
      openModal: true,
      selectedRessource: ressource,
      action: 'delete'
    })
  }

  /**
   * Gère l'appel de la modale en mode édition
   * @param ressource
   */
  onClickEditIconHandler(ressource) {
    this.setState({
      openModal: true,
      selectedRessource: {
        ...ressource,
        libelle: ressource.libelle ? ressource.libelle : ''
      },
      action: 'edit'
    })
  }

  /**
   * Gère la validation de la suppression du document
   */
  onClickDeleteHandler() {
    const {dispatch} = this.props;
    let {selectedRessource} = this.state;

    collectionActions(dispatch, 'ressources', 'DELETE', selectedRessource, () => {
      this.setState({
        openModal: false,
        selectedResource: null
      });

      collectionActions(dispatch, 'ressources', 'INDEX', {params: {item_uuid: selectedRessource.item_uuid}})
    });
  }

  /**
   * Gère la validation de l'édition du document
   */
  onClickEditHandler() {
    let {selectedRessource} = this.state;
    const {dispatch} = this.props;

    collectionActions(dispatch, 'ressources', 'UPDATE', selectedRessource, () => {
      this.setState({
        openModal: false,
        selectedResource: null,
        action: null
      })
    });
  }

  /**
   * Gestion de la validation par touche "entrée"
   */
  onPressEnter(e) {
    if (e.keyCode === 13 || e.which === 13) {
      this.onClickEditHandler()
    }
  }

  /**
   * Rendu du bouton d'import des documents
   * @return {[type]} [description]
   */
  getImportButton() {
    const {classes} = this.props;

    return (
      <Button
        title='Importer des documents'
        color='primary'
        variant="contained"
        className={classes.btnDropZone}
        onClick={() => {
          this.dropzoneRef.open()
        }}
      >
        <CloudUpload className={classes.uploadIcon}/> Importer des documents
      </Button>
    );
  }

  importDocumentsCallback(files) {
    const {dispatch, itemUuid, itemType} = this.props;
    let data = new FormData();

    files.forEach(file => {
      data.append('files[]', file);
    });

    data.append('item_uuid', itemUuid);
    data.append('item_type', itemType);
    data.append('ressource_type', 'document');

    importDocuments(dispatch, data)
  }

  setDropzoneState(enabled) {
    this.setState({
      enableDropzone: enabled,
    });
  }

  /**
   * Génère les documents
   * @returns {Array}
   */
  getDocuments() {
    let {ressourcesStore, display, showFavoriteOnCards} = this.props,
      documents = [];

    if (ressourcesStore.list) {
      let filteredListing = [];

      if (!display || !Array.isArray(display)) {
        filteredListing = ressourcesStore.list;
      } else {
        filteredListing = ressourcesStore.list.filter(ressource => display.indexOf(ressource.ressource_type) > -1)
      }

      documents.push(Object.keys(filteredListing).map(key => {
        let ressource = filteredListing[key],
          file = ressource.file;

        return <DocumentCard
          key={file.uuid}
          showFavorite={showFavoriteOnCards}
          ressource={ressource}
          onClick={this.onClickIconHandler.bind(this)}
          onClickEdit={this.onClickEditIconHandler.bind(this)}
          onClickDelete={this.onClickDeleteIconHandler.bind(this)}
          dropZoneStateCallback={this.setDropzoneState.bind(this)}
          refreshParams={{item_uuid: this.props.itemUuid}}
        />
      }));
    }

    return documents
  }

  /**
   * Gère le changement sur les attributs du document ( libelle uniquement pour l'instant )
   * @param name
   * @returns {Function}
   */
  handleChange = name => event => {
    const {selectedRessource} = this.state;

    this.setState({
      selectedRessource: {
        ...selectedRessource,
        [name]: event.target.value,
      }
    });
  };

  /**
   * Génère la modale d'édition de libellé et de suppression de document
   * @returns {*}
   */
  getModal() {
    const {action, openModal, selectedRessource} = this.state,
      {classes} = this.props;

    if (selectedRessource) {
      let modalTitle = 'Suppression du document',
        documentTitle = selectedRessource.libelle ? selectedRessource.libelle : selectedRessource.file.original_name,
        callback = null,
        content = <DialogContent> </DialogContent>;

      if (action === 'edit') {
        modalTitle = 'Modification du libellé du document';
        callback = this.onClickEditHandler.bind(this);
        content = <DialogContent>
          <TextField
            label="Libellé"
            className={classes.textField}
            value={selectedRessource.libelle}
            onChange={this.handleChange('libelle')}
            margin="normal"
            fullWidth
            onKeyPress={this.onPressEnter.bind(this)}
            autoFocus
          />
        </DialogContent>;
      } else if (action === 'delete') {
        modalTitle = 'Suppression du document';
        callback = this.onClickDeleteHandler.bind(this);
        content = <DialogContent>
          <Typography>Vous êtes sur le point de supprimer le document :</Typography>
          <Typography className={classes.deleteFileTitle}>- {documentTitle}</Typography>
        </DialogContent>;
      }

      return (
        <Dialog
          open={openModal}
          onClose={() => this.setState({openModal: false, selectedRessource: null, action: null})}
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            {modalTitle}
          </DialogTitle>
          {content}
          <DialogActions>
            <Button onClick={() => this.setState({openModal: false})}>
              Fermer
            </Button>
            <Button onClick={callback} color="primary">
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
  }

  getContent() {
    let {classes, ressourcesStore} = this.props;
    if (ressourcesStore.list !== null && ressourcesStore.list.length !== 0) {
      return (
        <Grid item xs={12} className={classes.cardContainer}>
          <Grid
            container
            spacing={24}
          >
            {this.getDocuments()}
          </Grid>
        </Grid>
      )
    }
  }

  isDisplayed() {
    let {classes, ressourcesStore} = this.props,
      result = classes.AcceptPanelDisplay;

    if (ressourcesStore.list && ressourcesStore.list.length > 0) {
      let test = ressourcesStore.list.find(ressource => {
        return ressource.ressource_type === 'document'
      });

      if (test !== undefined) result = classes.AcceptPanelDisplayNone
    }

    return result
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    let {classes, ressourcesStore} = this.props;
    if (ressourcesStore.fetching) return <CircularProgress/>;

    return (
      <Dropzone
        ref={(node) => {
          this.dropzoneRef = node;
        }}
        multiple={true}
        disableClick={true}
        disablePreview={true}
        accept={this.mimeTypeConfig}
        style={{height: this.dropZoneHeight}}
        className={classes.dropZone}
        acceptClassName={classes.dropZoneAccept}
        rejectClassName={classes.dropZoneReject}
        activeClassName="dropActive"
        onDropAccepted={this.importDocumentsCallback.bind(this)}
        disabled={this.state.enableDropzone === false}
      >
        <Grid
          container
          spacing={24}
          className={classes.container}
        >
          <Grid item xs={12} className={classes.dropZoneAcceptPanel + ' ' + this.isDisplayed()}>
            <EmptyDocumentsIcon className={classes.dropZoneAcceptPanelIcon}/>
            <Typography className={classes.dropZoneAcceptPanelText}>Déposez vos fichiers ici afin de
              les ajouter.</Typography>
          </Grid>
          <Grid item xs={12} className={classes.dropZoneRejectPanel}>
            <BlockIcon className={classes.dropZoneRejectPanelIcon}/>
            <Typography className={classes.dropZoneRejectPanelText}>Impossible d’importer ce type de
              document.</Typography>
          </Grid>
          <Grid item xs={12}>
            {this.getImportButton()}
          </Grid>
          {this.getContent()}
          {this.getModal()}
        </Grid>
      </Dropzone>
    )
  }
}

Documents = withStyles(DocumentCss)(Documents);

Documents.propTypes = {
  itemType: PropTypes.string.isRequired,
  itemUuid: PropTypes.string,
  showFavoriteOnCards: PropTypes.bool.isRequired,
  // display : Permet de n'afficher que certains types de ressources ( 'document', 'carrousel'... )
  display: PropTypes.array,
};

Documents = connect((store) => {
  return {
    ressourcesStore: store.collections.ressources
  }
})(Documents);

export default Documents
