import {CircularProgress, DialogContent, DialogTitle,} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {collectionActions, loadCollectionAttribute} from "../../../../reducers/collectionsReducer";
import Modal from '../Modal';

class CreateUpdate extends Component {
  state = {
    detail: {},
    deltailProps: null,
    prevProps: null
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const {collectionsStore, collectionName} = nextProps;
    let propsFollowed = ['openModal', 'uuidSelected', 'extradatasForm'],
      collectionNameStore = collectionsStore[collectionName],
      prevProps = {detail: collectionNameStore.detail};

    Object.keys(nextProps).filter(k => propsFollowed.includes(k)).forEach(k => prevProps[k] = nextProps[k]);

    let newState = {...prevState, prevProps: prevProps};

    loadCollectionAttribute(nextProps.dispatch, 'detail', collectionName, collectionNameStore, {uuid: nextProps.uuidSelected});
    if (JSON.stringify(collectionNameStore.detail) !== JSON.stringify(prevState.deltailProps)) {
      newState = {
        ...newState,
        detail: collectionNameStore.detail ? collectionNameStore.detail : {},
        deltailProps: collectionNameStore.detail
      };
    }

    // Ne pas mettre a jour le state par se biais si les props n'ont pas changés
    // Cette méthode est appelée suite a l'update du state également
    if (prevState.prevProps === newState.prevProps) {
      newState = null;
    }

    return newState;
  }

  /**
   * Handler du changement sur les inputs
   * @param name
   * @param event
   */
  onChangeHandler(name, event) {
    this.setState({
      detail: {
        ...this.state.detail,
        [name]: event.target.value
      }
    });
  }

  /**
   * Handler sur la modification
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    const {extradatasForm, collectionName, dispatch, actionsCallback} = this.props;
    let data = this.state.detail;
    if (!!extradatasForm) {
      data = {...data, ...extradatasForm}
    }
    collectionActions(dispatch, collectionName, !!data.uuid ? 'UPDATE' : 'CREATE', data, (detail) => {
      this.props.onCloseHandler();
      if (actionsCallback) actionsCallback(!!data.uuid ? 'update' : 'create', detail)
    })
  }


  /**
   * retourne le formulaire ou un loader
   * @return {[type]} [description]
   */
  getFormOrLoader() {
    const {collectionsStore, collectionName, createUpdateModalContent} = this.props;
    let collectionNameStore = collectionsStore[collectionName];
    let {detail} = collectionNameStore;
    if ((!detail && collectionNameStore.fetching) || !this.state.detail) return <CircularProgress
      className={this.props.classes.progress}/>;
    else {
      return createUpdateModalContent(this.state.detail, collectionNameStore, this.onChangeHandler.bind(this));
    }
  }

  /**
   * Retourne le contenu de la modal
   * @return {[type]} [description]
   */
  getModalContent() {
    const {collectionsStore, collectionName, createUpdateModalTitle} = this.props;

    let collectionNameStore = collectionsStore[collectionName];
    let {detail} = collectionNameStore;

    return [
      <DialogTitle key="title" id="alert-dialog-slide-title">
        {createUpdateModalTitle(detail)}
      </DialogTitle>,
      <DialogContent key="content">
        {this.getFormOrLoader()}
      </DialogContent>
    ];


  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {openModal, onCloseHandler,modalMaxWidth} = this.props;

    return (
      <Modal
        openModal={openModal}
        onCloseHandler={onCloseHandler}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        fullWidth={true}
        maxWidth={!!modalMaxWidth ? modalMaxWidth : 'sm'}
      >
        {this.getModalContent()}
      </Modal>
    );
  }
}


CreateUpdate.propTypes = {
  openModal: PropTypes.bool.isRequired,
  uuidSelected: PropTypes.string,
  onCloseHandler: PropTypes.func.isRequired,
  collectionName: PropTypes.string.isRequired,
  createUpdateModalTitle: PropTypes.func,
  createUpdateModalContent: PropTypes.func,
  extradatasForm: PropTypes.object,
  actionsCallback: PropTypes.func,
  modalMaxWidth: PropTypes.string
};

CreateUpdate = connect((store) => {
  return {
    collectionsStore: store.collections
  }
})(CreateUpdate);

export default withStyles(theme => ({}))(CreateUpdate)
