export default theme => ({
  statCard: {
    minWidth: 275,
    color: 'white'
  },
  statNumber: {
    fontSize: '2em',
    fontWeight: 'bold',
    color: 'white'
  },
  statLabel: {
    fontSize: '15px',
    color: 'white'
  },
  statCardContent: {
    position: 'relative',
    '&:hover': {
      '& svg': {
        transition: 'all .2s linear',
        fontSize: '6em'
      }
    }
  },
  statIconContainer: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: '0',
    color: 'rgba(0,0,0,.15)',
    '& svg': {
      transition: 'all .3s linear',
      fontSize: '5em'
    }
  },
  statCardActions: {
    backgroundColor: 'rgba(0,0,0,.1)',
    padding: 0
  },
  statButton: {
    color: 'white',
    width: '100%',
    margin: 0
  }
});