import {isMatchingRoute} from '../../../../router/Tools';
import {allRoutes} from '../../../routes.js'

/**
 * ###########################################################################
 * ##########################  FUNCTIONS #####################################
 * ###########################################################################
 */
export function addPathRoute(dispatch, route) {
  dispatch({
    type: 'APP_PATH_ROUTE_HISTORY',
    payload: route
  })
}

/**
 * ###########################################################################
 * ##########################  REDUCER   #####################################
 * ###########################################################################
 */

let initialState = {
  pathRoutesHistory: [],
  currentRouteConfig: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOGOUT': {
      return {...state, pathRoutesHistory: []};
    }
    case 'APP_PATH_ROUTE_HISTORY': {
      let pathRoutesHistory = state.pathRoutesHistory,
        routeConfig = allRoutes.find(routeConfig => isMatchingRoute(routeConfig.path, action.payload));

      routeConfig = {
        path: !!routeConfig ? routeConfig.path : pathRoutesHistory,
        public: !!routeConfig ? routeConfig.public : true,
        noContainer: !!routeConfig ? !!routeConfig.noContainer : true
      };
      pathRoutesHistory.unshift(action.payload);

      return {
        ...state,
        pathRoutesHistory: pathRoutesHistory,
        currentRouteConfig: routeConfig
      };
    }
    default:
      return state
  }
}
