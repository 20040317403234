import React, {Component} from 'react';
import {TextField} from '@material-ui/core';
import TextInputCss from "./css/TextInputCss";
import {withStyles} from "@material-ui/core/styles/index";

class TextInput extends Component {
  state = {};

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {...prevState};
    if (nextProps.name && nextProps.value !== prevState[nextProps.name]) {
      newState = {
        ...newState,
        [nextProps.name]: nextProps.value
      }
    }


    return newState;
  }

  /**
   * Retourne le message d'erreur pour le champ demandé
   * @param  {[type]} name [description]
   * @return {[type]}      [description]
   */
  getErrorMessage(name) {
    const {collectionStore} = this.props;
    if (collectionStore.errors && collectionStore.errors[name]) {
      return collectionStore.errors[name].map((err, index) => <span style={{display: 'block'}}
                                                                    key={index}>{err}</span>);
    } else return null;
  }

  /**
   * Handler du changement sur les inputs
   * @param  {[type]} name [description]
   * @return {[type]}      [description]
   */
  onChangeHandler(name, event) {
    this.setState({
      [name]: event.target.value
    });
    this.props.onChangeHandler(name, event)
  };

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {collectionStore, name} = this.props;
    let inputProps = Object.keys(this.props).filter(k => !['name', 'collectionStore', 'onChangeHandler', 'value'].includes(k)).reduce((obj, key) => {
      obj[key] = this.props[key];
      return obj;
    }, {});

    return (
      <TextField
        error={!!collectionStore.errors && !!collectionStore.errors[name]}
        helperText={this.getErrorMessage(name)}
        value={this.state[name] ? this.state[name] : ''}
        onChange={this.onChangeHandler.bind(this, name)}
        {...inputProps}
      >
        {this.props.children}
      </TextField>
    );
  }
}

export default withStyles(TextInputCss)(TextInput)
