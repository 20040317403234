import React, {Component} from 'react';
import {Switch} from 'react-router-dom';
import {Route} from 'react-router';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {CircularProgress, Fade, Grid} from '@material-ui/core';
import Error404 from '../../common/ErrorsPages/Error404';
import {routes} from './routes/routes';
import ProgrammeCss from './css/ProgrammeCss';
import {isMatchingRoute} from '../../../router/Tools';
import RouterConfig from '../../../router/config';

class Programme extends Component {
  render() {

    if (this.props.auth.fetching) {
      return (
        <Fade
          in={true}
          style={{
            transitionDelay: '800ms',
          }}
          unmountOnExit
        >
          <CircularProgress/>
        </Fade>
      )
    }

    if (!routes.find(r => isMatchingRoute(r.path, this.props.location[RouterConfig.pathname]))) return <Error404/>;
    return (
      <Grid
        container
        alignItems='center'
        justify='center'
      >
        <Switch>
          {routes.map(r => <Route path={r.path} exact={r.exact} key={r.path} component={r.component}/>)}
        </Switch>
      </Grid>
    );
  }
}

Programme = withStyles(ProgrammeCss)(Programme);

Programme = connect((store) => {
  return {
    auth: store.auth,
    location: store.router.location
  }
})(Programme);

export default Programme
