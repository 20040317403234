import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import HomeCss from './css/HomeCss';
import Beenhere from '@material-ui/icons/Beenhere';
import StatCard from "../../common/Components/StatCard";
import {loadCollectionAttribute} from "../../../reducers/collectionsReducer";

class Home extends Component {

  /**
   * Lors du montage du composant
   */
  componentDidMount() {
    if (!this.props.programmesStore || !this.props.programmesStore.list) {
      loadCollectionAttribute(this.props.dispatch, 'list', 'programmes', this.props.programmesStore);
    }
  }


  getStats(stats) {
    return (
      Object.keys(stats).map(key => {
        let stat = stats[key];

        return <StatCard key={key} stat={stat}/>
      })
    )
  }

  render() {
    const {classes} = this.props,
      stats = {
        utilisateurs: {
          label: 'Programmes',
          color: '#605ca8',
          value: (this.props.programmesStore && this.props.programmesStore.list ? this.props.programmesStore.list.length : '-'),
          link: '/programmes',
          icon: <Beenhere/>
        },
      };

    return (
      <Grid container spacing={24} className={classes.container}>
        {this.getStats(stats)}
      </Grid>
    );
  }
}

Home = withStyles(HomeCss)(Home);

Home = connect((store) => {
  return {
    programmesStore: store.collections.programmes
  }
})(Home);

export default Home
