import React, {Component} from 'react';
import {Avatar, Card, CardActions, CardHeader, CardMedia, Grid, IconButton, withStyles} from '@material-ui/core';
import DocumentCss from "./css/DocumentCss";
import PropTypes from 'prop-types';
import ArchiveIcon from '@material-ui/icons/Archive';
import BusinessIcon from '@material-ui/icons/Business';
import DeleteIcon from '@material-ui/icons/Delete';
import DefaultDocIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import MovieIcon from '@material-ui/icons/Movie';
import PhotoIcon from '@material-ui/icons/Photo';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import PublicIcon from '@material-ui/icons/Public';
import StarIcon from '@material-ui/icons/Star';
import connect from "react-redux/es/connect/connect";
import {moveDocumentBefore} from "./actions/DocumentsActions";
import DocumentIcon from '../../../assets/img/document.png';


class DocumentCard extends Component {

  state = {
    dragImg: null,
  }

  componentDidMount() {
    const img = new Image();
    img.src = DocumentIcon;
    img.onload = () => this.setState({ dragImg: img });
  }

  /**
   * Génère le titre des documents avec le boutons d'édition
   * @param ressource
   * @returns {*}
   */
  getDocumentTitle(ressource) {
    let titre = ressource.file.original_name;

    const {classes, onClickEdit} = this.props,
      editButton = <IconButton
        aria-label="Éditer le libellé du document"
        title="Éditer le libellé du document"
        className={classes.editButton}
        onClick={onClickEdit.bind(this, ressource)}
      >
        <EditIcon/>
      </IconButton>;

    if (ressource.libelle) titre = ressource.libelle;

    return <div>{titre.substring(0, 15) + (titre.length > 15 ? '...' : '')} {editButton}</div>
  }

  /**
   * Génère la mediaCard des documents
   * @param ressource
   * @returns {*}
   */
  getDocumentMimeType(ressource) {
    let {classes} = this.props,
      title = '',
      backgroundIcon = null,
      icon = null;

    switch (ressource.file.mime_type) {
      case 'application/zip':
        title = 'Document ZIP';
        backgroundIcon = <ArchiveIcon id='mediaIconBackground'
                                      className={classes.mediaIconBackground + ' ' + classes.mediaArchiveIconBackground}/>;
        icon = <ArchiveIcon id='mediaIcon' className={classes.mediaIcon + ' ' + classes.mediaArchiveIcon}/>;
        break;
      case 'application/pdf':
        title = 'Document PDF';
        backgroundIcon = <PdfIcon id='mediaIconBackground'
                                  className={classes.mediaIconBackground + ' ' + classes.mediaPdfIconBackground}/>;
        icon = <PdfIcon id='mediaIcon' className={classes.mediaIcon + ' ' + classes.mediaPdfIcon}/>;
        break;
      case 'image/png':
      case 'image/jpg':
      case 'image/gif':
      case 'image/jpeg':
        title = 'Document image';
        backgroundIcon = <PhotoIcon id='mediaIconBackground'
                                    className={classes.mediaIconBackground + ' ' + classes.mediaPhotoIconBackground}/>;
        icon = <PhotoIcon id='mediaIcon' className={classes.mediaIcon + ' ' + classes.mediaPhotoIcon}/>;
        break;
      case 'video/mpeg':
      case 'video/x-ms-wmv':
      case 'video/mp4':
      case 'video/x-msvideo':
        title = 'Document vidéo';
        backgroundIcon = <MovieIcon id='mediaIconBackground'
                                    className={classes.mediaIconBackground + ' ' + classes.mediaMovieIconBackground}/>;
        icon = <MovieIcon id='mediaIcon' className={classes.mediaIcon + ' ' + classes.mediaMovieIcon}/>;
        break;
      default:
        title = 'Document';
        backgroundIcon = <DefaultDocIcon id='mediaIconBackground'
                                         className={classes.mediaIconBackground + ' ' + classes.mediaDefaultIconBackground}/>;
        icon = <DefaultDocIcon id='mediaIcon' className={classes.mediaIcon + ' ' + classes.mediaDefaultIcon}/>;
        break;
    }

    return (
      <CardMedia
        image={'#'}
        className={classes.media}
        title={title}
        onClick={() => window.open(ressource.file.cellar_path)}
      >
        {backgroundIcon}
        {icon}
      </CardMedia>
    )
  }

  render() {
    const {classes, onClick, onClickDelete, ressource, showFavorite} = this.props;

    return (
      <Grid item xs={3} sm={3} md={3} lg={3} xl={2} style={{padding: '8px'}} >
        <Card className={classes.card} draggable="true" onDragStart={(e) => {
          e.dataTransfer.setData('src-uuid', ressource.uuid);
          e.dataTransfer.setDragImage(this.state.dragImg, 25, 25);
          this.props.dropZoneStateCallback(false);
        }} onDragOver={(e) => {
          e.preventDefault();
        }} onDrop={(e) => {
          if (e.dataTransfer.getData('src-uuid') === ressource.uuid) {
            return;
          }
          moveDocumentBefore(
            this.props.dispatch,
            e.dataTransfer.getData('src-uuid'), // move this...
            ressource.uuid, // ... before this
            this.props.refreshParams
          );
        }} onDragEnd={(e) => {
          this.props.dropZoneStateCallback(true);
        }}>
          <CardHeader
            className={classes.cardHeader}
            avatar={
              <Avatar aria-label="Ordre d'affichage" className={classes.number}>
                {ressource.rank}
              </Avatar>
            }
            title={this.getDocumentTitle(ressource)}
          />
          {this.getDocumentMimeType(ressource)}
          <CardActions className={classes.actions} disableActionSpacing>
            <IconButton
              aria-label="Public"
              title="Public"
              style={ressource.diffusion === 'public' ? {color: '#2196f3'} : null}
              onClick={onClick.bind(this, 'public', ressource)}
            >
              <PublicIcon/>
            </IconButton>
            <IconButton
              aria-label="Accessible aux professionnels"
              title="Accessible aux professionnels"
              style={ressource.diffusion === 'pro' ? {color: '#d03c20'} : null}
              onClick={onClick.bind(this, 'pro', ressource)}
            >
              <BusinessIcon/>
            </IconButton>
            {showFavorite ? (
            <IconButton
              aria-label="Par défaut sur les lots"
              title="Par défaut sur les lots"
              style={ressource.favorite ? {color: '#FFEB3B'} : null}
              onClick={onClick.bind(this, 'favorite', ressource)}
            >
              <StarIcon/>
            </IconButton>) : null }
            <IconButton
              aria-label="Supprimer le document"
              title="Supprimer le document"
              className={classes.deleteIcon}
              onClick={onClickDelete.bind(this, ressource)}
            >
              <DeleteIcon/>
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    )
  }
}

DocumentCard = withStyles(DocumentCss)(DocumentCard);

DocumentCard.propTypes = {
  ressource: PropTypes.object.isRequired,
  showFavorite: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  dropZoneStateCallback: PropTypes.func.isRequired,
  refreshParams: PropTypes.object.isRequired,
};

DocumentCard = connect((store) => {
  return {}
})(DocumentCard);

export default DocumentCard
