import {themeComplement} from "../../AppAdmin/css/theme";

export default theme => ({
  imageCarrouselContainer: {
    backgroundColor: '#f5f5f5',
    width: '100%',
    margin: theme.spacing.unit * 1,
    marginLeft: 0,
    marginRight: 0,
    minHeight: '181px',
  },
  imageCarrouselContainerMinHeight: {
    minHeight: 'inherit',
  },
  imageCarrouselCardContainer: {
    transition: 'box-shadow 0.1s ease-in-out',
    boxShadow: theme.shadows[1],
    borderRadius: '4px',
    padding: '0!important',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      boxShadow: theme.shadows[4],
      cursor: 'pointer',
      '& $imageCarrouselCardHeader': {
        transform: 'translateY(0)',
      }
    },
  },
  imageCarrouselCardAddicon: {
    color: 'white',
    width: '100%',
    height: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  imageCarrouselCardHeader: {
    padding: '14px 8px',
    backgroundColor: '#263238a3',
    color: 'white',
    position: 'absolute',
    top: 0,
    width: '100%',
    boxSizing: 'border-box',
    transition: 'all 0.1s linear',
    transform: 'translateY(-100%)',
  },
  imageCarrouselCardIcon: {
    marginRight: '8px',
    float: 'right',
    '&:hover': {
      color: themeComplement.palette.notifications.error.color,
    }
  },
  imageCarrouselCardDeleteIcon: {
    color: 'white',
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: '50% 4px 4px 50%',
    '&:hover': {
      color: themeComplement.palette.notifications.error.color,
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
    }
  },
  imageCarrouselCardFirstImageIcon: {
    color: 'white',
    position: 'absolute',
    top: 0,
    right: '40px',
    borderRadius: '0px',
    '&:hover': {
      color: themeComplement.palette.notifications.error.color,
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
    }
  },
  imageCarrouselCard: {
    display: 'block',
    width: '100%',
    borderRadius: '4px',
  },

  CarrouselDropZone: {
    transitionDuration: '0.15s',
    width: '100%',
  },
  CarrouselDropZoneAccept: {
    '& $CarrouselDropZoneAcceptPanel': {
      display: 'block',
    },
    '& $imageCarrouselCardContainer': {
      opacity: '0.1',
    },
    '& $imageCarrouselCardAddicon': {
      opacity: '0.1',
    },
    '& $CarrouselDropZoneAcceptPanelIcon': {
      color: theme.palette.primary.main,
    },
    '& $CarrouselDropZoneAcceptPanelText': {
      color: theme.palette.primary.main,
    },
  },
  CarrouselDropZoneReject: {
    pointer: 'not-allowed',
    '& $CarrouselDropZoneRejectPanel': {
      display: 'block',
    },
    '& $imageCarrouselCardContainer': {
      opacity: '0.1',
    },
    '& $imageCarrouselCardAddicon': {
      opacity: '0.1',
    },
    '& $CarrouselDropZoneAcceptPanelIcon': {
      display: 'none',
    },
    '& $CarrouselDropZoneAcceptPanelText': {
      display: 'none',
    },
  },
  CarrouselDropZoneAcceptPanel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  CarrouselDropZoneAcceptPanelIcon: {
    fontSize: '9em',
    color: '#80808046'
  },
  CarrouselDropZoneAcceptPanelText: {
    color: '#808080',
  },
  CarrouselDropZoneRejectPanel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'none',
  },
  CarrouselDropZoneRejectPanelIcon: {
    fontSize: '9em',
    color: '#d67a7a46'
  },
  CarrouselDropZoneRejectPanelText: {
    color: themeComplement.palette.notifications.error.color,
  },
  CarrouselLabel: {
    paddingLeft: '1em',
  },
  AcceptPanelDisplayNone: {
    display: 'none',
  },
  AcceptPanelDisplay: {
    display: 'block',
  },
})
