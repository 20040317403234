import {axiosClient} from '../../../../axios';
import {addNotification, axiosErrorHandler} from '../../../../reducers/notificationReducer';
import {themeComplement} from "../../AppAdmin/css/theme";

/**
 * ###########################################################################
 * ##########################  FUNCTIONS #####################################
 * ###########################################################################
 */

/**
 * Permet d'importer les lots via le fichier excel
 * @param  {[type]} dispatch       [description]
 * @param  {[type]} data           [description]
 * @return {[type]}                [description]
 */
export function importDocuments(dispatch, data) {
  dispatch({
    type: 'LIST_PENDING',
    collection: 'ressources'
  });
  axiosClient.post('ressources', data)
    .then((response) => {
      dispatch({
        type: 'LIST_FULFILLED',
        collection: 'ressources',
        payload: response.data
      });
      addNotification(dispatch, {
        message: 'Documents importés avec succès',
        bgColor: themeComplement.palette.notifications.success.color
      })
    })
    .catch((err) => {
      axiosErrorHandler(dispatch, err)
    })
}

export function moveDocumentBefore(dispatch, sourceUuid, destUuid, data) {
  axiosClient.post('ressources/move/' + sourceUuid + '/before/' + destUuid, data)
    .then((response) => {
      dispatch({
        type: 'LIST_FULFILLED',
        collection: 'ressources',
        payload: response.data
      });
      addNotification(dispatch, {
        message: 'Document déplacé',
        bgColor: themeComplement.palette.notifications.success.color
      })
    })
    .catch((err) => {
      axiosErrorHandler(dispatch, err)
    })
}

export function moveDocumentFirst(dispatch, uuid, data) {
  axiosClient.post('ressources/move-first/' + uuid, data)
    .then((response) => {
      dispatch({
        type: 'LIST_FULFILLED',
        collection: 'ressources',
        payload: response.data
      });
      addNotification(dispatch, {
        message: 'Document déplacé',
        bgColor: themeComplement.palette.notifications.success.color
      })
    })
    .catch((err) => {
      axiosErrorHandler(dispatch, err)
    })
}
