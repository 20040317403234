import React, {Component} from 'react';
// import RouterConfig from '../../../router/config';

export default class Home extends Component {
  render() {
    return (
      <div>Compo sur page d’accueil Wordpress</div>
    );
  }
}
