import {Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch,} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import TextInput from '../../common/Components/TextInput';
import ProgrammeCss from './css/ProgrammeCss';
import DetailGeneralCarrousel from "./DetailGeneralCarrousel";

class DetailGeneral extends Component {
  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    let {
        classes,
        onChangeHandler,
        onChangeSelectHandler,
        onChangeSwitchHandler,
        onSubmithandler,
        programme,
        programmesStore
      } = this.props,
      {
        nom,
        visible,
        description,
        avancement,
        bbc,
        rt2012,
        re2020,
        coordonnees_gps,
        accroche,
        livraison,
        ville,
        rue,
        points_cles,
        y_vivre,
        environnement,
        prestations,
      } = programme;

    return (
      <Grid container spacing={24} className={classes.programmeContainer}>
        <Grid item xs={6}>
          <TextInput
            id="nom"
            label="Nom"
            value={nom}
            margin="normal"
            collectionStore={programmesStore}
            name='nom'
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControl} margin="normal" fullWidth>
            <InputLabel htmlFor="avancement">Avancement</InputLabel>
            <Select
              value={avancement}
              onChange={onChangeSelectHandler.bind(this)}
              inputProps={{
                name: 'avancement',
                id: 'avancement',
              }}
            >
              <MenuItem value={'projet'}>1. Projet</MenuItem>
              <MenuItem value={'lancement_commercial'}>2. Lancement commercial</MenuItem>
              <MenuItem value={'demarrage_travaux'}>3. Démarrage des travaux</MenuItem>
              <MenuItem value={'en_cours_construction'}>4. En cours de construction</MenuItem>
              <MenuItem value={'livraison'}>5. Livraison</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="livraison"
            value={livraison}
            name='livraison'
            label="Livraison"
            placeholder="exemple : 2021T3"
            margin="normal"
            collectionStore={programmesStore}
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="ville"
            value={ville}
            name='ville'
            label="Ville"
            margin="normal"
            collectionStore={programmesStore}
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="rue"
            value={rue}
            name='rue'
            label="Rue, emplacement"
            margin="normal"
            collectionStore={programmesStore}
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="coordonnees_gps"
            name='coordonnees_gps'
            value={coordonnees_gps}
            label="Coordonnées GPS"
            margin="normal"
            collectionStore={programmesStore}
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
            placeholder="exemple : 47.208176, -1.563174"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="accroche"
            value={accroche}
            name='accroche'
            label="Accroche"
            placeholder="exemple : 9 maisons 4 pièces"
            margin="normal"
            collectionStore={programmesStore}
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="description"
            label="Description courte"
            value={description}
            margin="normal"
            collectionStore={programmesStore}
            name='description'
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
            multiline
            rows="6"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="points_cles"
            label="Points clés (3 max)"
            value={points_cles}
            name='points_cles'
            margin="normal"
            collectionStore={programmesStore}
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
            multiline
            rows="6"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="y_vivre"
            label="Y vivre"
            value={y_vivre}
            name='y_vivre'
            margin="normal"
            collectionStore={programmesStore}
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
            multiline
            rows="6"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="environnement"
            label="Environnement"
            value={environnement}
            name='environnement'
            margin="normal"
            collectionStore={programmesStore}
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
            multiline
            rows="6"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="prestations"
            label="Prestations"
            value={prestations}
            name='prestations'
            margin="normal"
            collectionStore={programmesStore}
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
            multiline
            rows="6"
          />
        </Grid>
        <Grid item xs={6} className={classes.vCenterCell}>
          <Grid item xs={3} className={classes.vCenterCell}>
            <FormControlLabel
              control={
                <Switch
                  checked={visible}
                  onChange={onChangeSwitchHandler.bind(this, 'visible')}
                  color="primary"
                />
              }
              label="Visible"
            />
          </Grid>
          <Grid item xs={3} className={classes.vCenterCell}>
            <FormControlLabel
              control={
                <Switch
                  checked={bbc}
                  onChange={onChangeSwitchHandler.bind(this, 'bbc')}
                  color="primary"
                />
              }
              label="BBC"
            />
          </Grid>
          <Grid item xs={3} className={classes.vCenterCell}>
            <FormControlLabel
              control={
                <Switch
                  checked={rt2012}
                  onChange={onChangeSwitchHandler.bind(this, 'rt2012')}
                  color="primary"
                />
              }
              label="RT2012"
            />
          </Grid>
          <Grid item xs={3} className={classes.vCenterCell}>
            <FormControlLabel
              control={
                <Switch
                  checked={re2020}
                  onChange={onChangeSwitchHandler.bind(this, 're2020')}
                  color="primary"
                />
              }
              label="RE2020"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            color='primary'
            variant="contained"
            onClick={onSubmithandler}
            className={classes.bigValid}
          >
            Enregistrer les modifications
          </Button>
        </Grid>
        <DetailGeneralCarrousel/>
      </Grid>
    )
  }
}

DetailGeneral = withStyles(ProgrammeCss)(DetailGeneral);

DetailGeneral.propTypes = {
  programmesStore: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onChangeSelectHandler: PropTypes.func.isRequired,
  onChangeSwitchHandler: PropTypes.func.isRequired,
  onSubmithandler: PropTypes.func.isRequired,
  programme: PropTypes.object.isRequired
};

// DetailGeneral = connect((store) => {
//   return {
//     ressourcesStore: store.collections.ressources
//   }
// })(DetailGeneral);

export default DetailGeneral
