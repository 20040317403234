export default theme => ({
  // Collection.js
  addButton: {
    marginTop: '0.5em',
    marginLeft: '2em',
    marginRight: '0.5em',
  },
  // Detail.js
  programmeContainer: {
    textAlign: 'left'
  },
  programmeTitleContainer: {
    padding: '0 0 ' + theme.spacing.unit + 'px 0!important'
  },
  programmeTypoContainer: {
    float: 'left',
    padding: theme.spacing.unit + 'px 0',
    height: '24px',
    color: theme.palette.primary.main,
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing.unit
  },
  programmeTitleIcon: {
    marginRight: theme.spacing.unit
  },
  tabsContainer: {

  },
  appBar: {
    boxShadow: 'none'
  },
  tabContentContainer: {
    width: '100%'
  },
  editButton: {
    float: 'right'
  },
  returnButton: {
    float: 'right'
  },
  // DetailGeneral.js

  // DetailGeneralCarrousel.js => CarrouselCss.js

  // DetailLots.js
  uploadIcon: {
    marginRight: theme.spacing.unit,
  },
  lotsActionButtons: {
    float: 'right',
  },
  btnDropZone: {
    float: 'right',
  },
  dataTableLots: {
    transitionDuration: '0.15s',
  },
  dataTableAcces: {
    transitionDuration: '0.15s',
  },
  dropZone: {
    transitionDuration: '0.15s',
  },
  dropZoneAccept: {
    '& $dropZoneAcceptPanel': {
      opacity: '1',
    },
    '& $dataTableLots': {
      opacity: '0.1',
    },
  },
  dropZoneReject: {
    pointer: 'not-allowed',
    '& $dropZoneRejectPanel': {
      opacity: '1',
    },
    '& $dataTableLots': {
      opacity: '0.1',
    },
  },
  dropZoneAcceptPanel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    opacity: '0',
  },
  dropZoneAcceptPanelIcon: {
    paddingTop: '0.2em',
    fontSize: '14em',
    color: '#80808046'
  },
  dropZoneAcceptPanelText: {
    color: '#808080',
  },
  dropZoneRejectPanel: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    opacity: '0',
  },
  dropZoneRejectPanelIcon: {
    paddingTop: '0.2em',
    fontSize: '14em',
    color: '#d67a7a46'
  },
  dropZoneRejectPanelText: {
    color: '#d67a7a',
  },
  dialogContent: {
    paddingTop: theme.spacing.unit * 10,
  },
  dialogContentData: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  bigValid: {
    float: 'right',
    minWidth: '30%',
  },
  vCenterCell: {
    alignItems: 'center',
    display: 'flex',
  },
});
