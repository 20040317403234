import collections from './collections';
import {axiosClient} from '../axios';
import {addNotification, axiosErrorHandler} from './notificationReducer';
import {themeComplement} from "../components/admin/AppAdmin/css/theme";

/**
 * Retourne le type en fonction de l'action
 * @param {*} action
 */
function getType(action) {
  let relations = {
    'RESET_DETAIL': 'RESET_DETAIL',
    'RESET_ERRORS': 'RESET_ERRORS',
    'SHOW': 'DETAIL_FULFILLED',
    'INDEX': 'LIST_FULFILLED',
    'CREATE': 'CREATED',
    'UPDATE': 'UPDATED',
    'DELETE': 'DELETED'
  };
  return relations[action]
}

/**
 * Permet de gérer l'envoie de notifications
 * @param {*} dispatch
 * @param {*} collectionActions
 * @param {*} collectionName
 */
function sendNotification(dispatch, collectionActions, collectionName) {
  if (['CREATE', 'UPDATE', 'DELETE'].indexOf(collectionActions) > -1) {
    addNotification(dispatch, {
      message: getNotificationMessage(collectionActions, collectionName),
      bgColor: themeComplement.palette.notifications.success.color
    })
  }
}

/**
 * Retourne le message de la notification
 * @param {*} collectionActions
 * @param {*} collectionName
 */
function getNotificationMessage(collectionActions, collectionName) {
  collectionName = collectionName.endsWith('s') ? collectionName.substring(0, collectionName.length - 1) : collectionName;
  let message = capitalize(collectionName) + ' ';
  switch (collectionActions) {
    case 'CREATE':
      message += ' créé';
      break;
    case 'UPDATE':
      message += ' modifié';
      break;
    case 'DELETE':
      message += ' supprimé';
      break;
    default :
      break;
  }
  return message
}

/**
 * Fonction pour passer la première lettre en majuscule
 * @param {*} param0
 */
const capitalize = ([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase();

/**
 * Charge l'attribut d'une collection vérifie automatiquement si il est null ou déjà chargé
 * @param  {[type]}  dispatch        [description]
 * @param  {[type]}  attribute       [description]
 * @param  {[type]}  collectionName  [description]
 * @param  {[type]}  collectionStore [description]
 * @param  {[type]}  [data=null]     [description]
 * @param  {Boolean} [force=false]   [description]
 * @return {[type]}                  [description]
 */
export function loadCollectionAttribute(dispatch, attribute, collectionName, collectionStore, data = null, force = false) {
  //Vérifications
  if (!['list', 'detail'].includes(attribute)) throw new Error('Seul les attributs list et détail sont géré par cette méthode');
  if (!collections.includes(collectionName)) throw new Error(`Collection ${collectionName} inconnue !`);
  if (!(typeof collectionStore === 'object' && Object.keys(collectionStore).every(k => Object.keys(defaultState).includes(k)))) {
    throw new Error(`CollectionStore non conforme`);
  }
  let action = null;
  if (!collectionStore.fetching) {
    if (attribute === 'list' && (!collectionStore[attribute] || force)) action = 'INDEX';
    if (attribute === 'detail' && ((!collectionStore[attribute] || collectionStore[attribute].uuid !== data.uuid) || force) && !!data && !!data.uuid) action = 'SHOW';
    if (attribute === 'detail' && (!data.uuid || !data) && !!collectionStore[attribute]) action = 'RESET_DETAIL'
  }

  if (!!action) collectionActions(dispatch, collectionName, action, data, null)
}

/**
 * Permet de lancer les actions par défauts sur les collections
 * @param {function} dispatch Function de dispatch de redux
 * @param {string} collectionName Nom de la collection a gérer
 * @param {string} action nom de l'action (SHOW, INDEX, CREATE, UPDATE, DELETE)
 * @param {object} data object des parametres a passer a axios
 * @param {function} cb Callback
 */
export function collectionActions(dispatch, collectionName, action, data = null, cb = null) {
  if (!collections.includes(collectionName)) throw new Error(`Collection ${collectionName} inconnue !`);

  let promise = null,
    type = getType(action);

  switch (action) {
    case 'SHOW':
      dispatch({type: 'DETAIL_PENDING', collection: collectionName});
      promise = axiosClient.get(`/${collectionName}/${data.uuid}`, data);
      break;
    case 'INDEX' :
      dispatch({type: 'LIST_PENDING', collection: collectionName});
      promise = axiosClient.get(`/${collectionName}`, data);
      break;
    case 'CREATE' :
      promise = axiosClient.post(`/${collectionName}`, data);
      break;
    case 'UPDATE' :
      promise = axiosClient.put(`/${collectionName}/${data.uuid}`, data);
      break;
    case 'DELETE' :
      promise = axiosClient.delete(`/${collectionName}/${data.uuid}`, data);
      break;
    case 'RESET_DETAIL':
    case 'RESET_ERRORS':
      dispatch({type: type, collection: collectionName});
      break;
    default:
      throw new Error(`Action ${action} inconnue !`)
  }

  if (!!promise) {
    promise.then((response) => {
      if (typeof response.data !== 'object') throw new Error('Format de réponse erroné');
      dispatch({
        type,
        collection: collectionName,
        payload: response.data
      });
      sendNotification(dispatch, action, collectionName);
      if (!!cb) cb(response.data)
    }).catch(error => {
      axiosErrorHandler(dispatch, error);
      if (!!error.response && error.response.status === 404) {
        addNotification(dispatch, {
          message: capitalize(collectionName) + ' non trouvé',
          bgColor: themeComplement.palette.notifications.error.color
        })
      }
      else if (!!error.response &&
        !!error.response.data &&
        !!error.response.data.errors) {
        dispatch({
          type: 'ERRORS',
          collection: collectionName,
          payload: error.response.data.errors
        })
      }
    })
  }
}

/**
 * État initial par défaut des collections
 */
let defaultState = {
  fetching: false,
  fetched: false,
  list: null,
  detail: null,
  errors: null
};

/**
 * État initial par défaut du store
 */
const initialState = collections.reduce((obj, current) => {
  obj[current] = defaultState;
  return obj
}, {});

/**
 * Storage
 */
export default (state = initialState, action) => {
  if (action.collection === undefined) return state;

  let collection = action.collection;

  switch (action.type) {
    case 'RESET_ERRORS': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: false,
          errors: null
        }
      }
    }
    case 'RESET_DETAIL': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: null
        }
      }
    }
    case 'DETAIL_PENDING': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: true,
          fetched: false,
          detail: null
        }
      }
    }
    case 'DETAIL_FULFILLED': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: action.payload
        }
      }
    }
    case 'LIST_PENDING': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: true,
          fetched: false,
          list: null
        }
      }
    }
    case 'LIST_FULFILLED': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          list: action.payload
        }
      }
    }
    case 'CREATED': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: action.payload,
          list: state[collection].list ? state[collection].list.concat([action.payload]) : null

        }
      }
    }
    case 'UPDATED': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: action.payload,
          list: state[collection].list ? state[collection].list.map(item => item.uuid === action.payload.uuid ? action.payload : item) : null

        }
      }
    }
    case 'DELETED': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: null,
          list: state[collection].list ? state[collection].list.filter(item => item.uuid !== action.payload.uuid) : null

        }
      }
    }
    case 'ERRORS': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: false,
          errors: action.payload
        }
      };
    }
    default:
      return state;
  }
};
