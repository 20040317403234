import {Button, CircularProgress, DialogContent, DialogTitle, Grid, Grow, IconButton} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Add, Delete, Edit} from '@material-ui/icons';
import {Cell, DataTable} from '@oniti/datatable-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from "react-redux";
import {collectionActions, loadCollectionAttribute} from "../../../../reducers/collectionsReducer";

import Modal from '../Modal';
import CreateUpdate from './CreateUpdate';
import CollectionCrudCss from './css/CollectionCrudCss';

class CollectionCrud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      openModal: false,
      openDeleteModal: false,
      uuidSelected: null,
      detail: null
    };

    if (!props.collectionsStore[props.collectionName]) throw new Error('Collection inconnue')
  }

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const {collectionsStore, collectionName} = nextProps;

    if (collectionsStore[collectionName].list !== prevState.list) {
      return {
        list: collectionsStore[collectionName].list
      };
    }

    return null;
  }

  /**
   * Lors du montage du composant
   */
  componentDidMount() {
    const {collectionsStore, collectionName, dispatch} = this.props;
    loadCollectionAttribute(dispatch, 'list', collectionName, collectionsStore[collectionName]);
  }

  /**
   * Handler sur edit
   * @param  {[type]} detail [description]
   * @return {[type]}      [description]
   */
  onClickEditHandler(detail) {
    this.setState({
      openModal: true,
      uuidSelected: detail.uuid
    });
    if (this.props.onModalOpen) {
      this.props.onModalOpen(detail);
    }
  }

  /**
   * Fermeture de la modal de delete
   * @return {[type]} [description]
   */
  onCloseDeleteModalHandler() {
    this.setState({
      openDeleteModal: false,
      detail: null
    })
  }

  /**
   * Validation de l'action delete
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    const {dispatch, collectionName, actionsCallback} = this.props;
    collectionActions(dispatch, collectionName, 'DELETE', this.state.detail, () => {
      if (actionsCallback) actionsCallback('delete', this.state.detail)
    });
    this.setState({
      openDeleteModal: false,
      user: null
    })
  }

  /**
   * Clic sur le bouton Delete
   * @param  {[type]} detail [description]
   * @return {[type]}        [description]
   */
  onClickDeleteHandler(detail) {
    this.setState({
      openDeleteModal: true,
      detail
    })
  }

  /**
   * Handler pour ajouter un detail de la collection
   */
  addBtnCallBack() {
    this.setState({
      openModal: true,
      uuidSelected: null
    });
    if (this.props.onModalOpen) {
      this.props.onModalOpen({});
    }
  }

  /**
   * Handler sur la fermeture de la modal
   * @return {[type]} [description]
   */
  onCloseModalHandler() {
    const {collectionName, dispatch} = this.props;
    this.setState({
      openModal: false,
      uuidSelected: null
    });
    collectionActions(dispatch, collectionName, 'RESET_ERRORS')
  }

  /**
   * Rendu de la cellule des actions
   * @param  {[type]} object [description]
   * @return {[type]}       [description]
   */
  formatActions(object) {
    const {classes, showBtnEdit, showBtnDelete} = this.props;
    let {additionnalControllers} = this.props;

    additionnalControllers = additionnalControllers ? additionnalControllers : [];

    let btnEdit = (
        <IconButton
          aria-label="Edit"
          key="edit"
          color="primary"
          onClick={this.onClickEditHandler.bind(this, object)}
          className={classes.button}
          title="Éditer"
        >
          <Edit/>
        </IconButton>
      ),
      btnDelete = (
        <IconButton
          aria-label="Delete"
          onClick={this.onClickDeleteHandler.bind(this, object)}
          className={classes.button}
          key="delete"
          title="Supprimer"
        >
          <Delete/>
        </IconButton>
      );

    let result = [];

    additionnalControllers.forEach(f => result.push(f(object)));

    return [
      showBtnDelete ? btnDelete : null,
      showBtnEdit ? btnEdit : null
    ].concat(result);
  }

  /**
   * Bouton d'ajout d'un utilisateur
   * @return {[type]} [description]
   */
  getBtnAdd() {
    let {classes} = this.props;

    return (
      <Button
        variant="fab"
        color="primary"
        aria-label="add"
        mini
        onClick={this.addBtnCallBack.bind(this)}
        className={classes.addButton}
        title="Ajouter"
      >
        <Add style={{fontSize: 32}}/>
      </Button>
    );
  }

  /**
   * Retourne les Cells du datatable
   * @return {[type]} [description]
   */
  getCells() {
    const {showBtnEdit, showBtnDelete, cellsConfig} = this.props;
    let cells = cellsConfig.map((conf, index) => <Cell key={index} {...conf} />);
    if (showBtnEdit || showBtnDelete) cells.push(
      <Cell
        key="crud"
        format={this.formatActions.bind(this)}
        sortable={false}
      />
    );

    return cells;
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {
      classes,
      datatableConfig,
      collectionName,
      showBtnAdd,
      showBtnEdit,
      showBtnDelete,
      deleteModalTitle,
      deleteModalContent,
      createUpdateModalTitle,
      createUpdateModalContent,
      extradatasForm,
      actionsCallback,
      dataTableExtraNodes,
      modalMaxWidth
    } = this.props;

    let extraNodes = null;

    if(showBtnAdd) {
      extraNodes = [{
        element: this.getBtnAdd(),
        position: 'top-right'
      }]
    }

    if(!!dataTableExtraNodes) {
      if(extraNodes === null) extraNodes = dataTableExtraNodes;
      else extraNodes = extraNodes.concat(dataTableExtraNodes)
    }

    if (!this.state.list) return <CircularProgress/>;

    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Grow in={true}>
              <DataTable
                datas={this.state.list ? this.state.list : []}
                {...datatableConfig}
                extraNodes={extraNodes}
              >
                {this.getCells()}
              </DataTable>
            </Grow>
          </Grid>
        </Grid>

        {showBtnDelete ? <Modal
          openModal={this.state.openDeleteModal}
          onCloseHandler={this.onCloseDeleteModalHandler.bind(this)}
          onSubmitHandler={this.onSubmitHandler.bind(this)}
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            {deleteModalTitle(this.state.detail)}
          </DialogTitle>
          <DialogContent>
            {deleteModalContent(this.state.detail)}
          </DialogContent>
        </Modal> : null}

        {showBtnAdd || showBtnEdit ? <CreateUpdate
          openModal={this.state.openModal}
          uuidSelected={this.state.uuidSelected}
          onCloseHandler={this.onCloseModalHandler.bind(this)}
          collectionName={collectionName}
          createUpdateModalTitle={createUpdateModalTitle}
          createUpdateModalContent={createUpdateModalContent}
          extradatasForm={extradatasForm}
          actionsCallback={actionsCallback}
          modalMaxWidth={modalMaxWidth}
        /> : null}

      </div>
    );
  }
}

CollectionCrud = withStyles(CollectionCrudCss)(CollectionCrud);

CollectionCrud = connect((store) => {
  return {
    collectionsStore: store.collections
  }
})(CollectionCrud);

CollectionCrud.propTypes = {
  collectionName: PropTypes.string.isRequired,
  cellsConfig: PropTypes.array.isRequired,
  datatableConfig: PropTypes.object.isRequired,
  showBtnEdit: PropTypes.bool,
  showBtnAdd: PropTypes.bool,
  showBtnDelete: PropTypes.bool,
  deleteModalTitle: PropTypes.func,
  deleteModalContent: PropTypes.func,
  createUpdateModalTitle: PropTypes.func,
  createUpdateModalContent: PropTypes.func,
  extradatasForm: PropTypes.object,
  additionnalControllers: PropTypes.array,
  actionsCallback: PropTypes.func,
  dataTableExtraNodes: PropTypes.arrayOf(PropTypes.object),
  modalMaxWidth: PropTypes.string,
  onModalOpen: PropTypes.func,
};

export default CollectionCrud
