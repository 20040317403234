import {axiosClient} from '../../../../axios';
import {addNotification, axiosErrorHandler} from '../../../../reducers/notificationReducer';
import {themeComplement} from "../../AppAdmin/css/theme";
import {buildRoute} from '../../../../router/Tools';

/**
 * Création d'un programme. Je (Julien) pense que je devrais tenter de faire
 * directement appel au collectionCRUD (mais il faut récup le uuid pour faire
 * la redirection)
 * @param  {[type]} dispatch
 * @param  {[type]} data
 * @return {[type]}          [description]
 */
export function createProgramme(dispatch, data, history) {
  axiosClient.post('programmes', { nom: data.new_programme })
    .then((response) => {
      addNotification(dispatch, {
        message: 'programme créé avec succès',
        bgColor: themeComplement.palette.notifications.success.color,
      });
      dispatch({
        type: 'CREATED',
        collection: 'programmes',
        payload: response.data,
      });
      history.push(buildRoute('/programmes/:uuid/general', {uuid: response.data.uuid}));
    })
    .catch((err) => {
      axiosErrorHandler(dispatch, err)
    })
}

// ajoute ou supprime l'utilisateur aux forbiddenUsers
export function userAllowOrForbid(dispatch, programme_uuid, user_uuid, allow, cb) {
  if (allow) {
    // on le supprime des forbiddenUsers
    axiosClient.delete('programmes/' + programme_uuid + '/forbiddenUsers/' + user_uuid)
      .then((response) => {
        addNotification(dispatch, {
          message: 'Autorisation ajoutée',
          bgColor: themeComplement.palette.notifications.success.color,
        });
        dispatch({
          type: 'DETAIL_FULFILLED',
          collection: 'programmes',
          payload: response.data,
        });
        // if (cb) cb(response.data);
      })
      .catch((err) => {
        axiosErrorHandler(dispatch, err)
      });
  } else {
    axiosClient.post('programmes/' + programme_uuid + '/forbiddenUsers', { user_uuid: user_uuid })
      .then((response) => {
        addNotification(dispatch, {
          message: 'Autorisation retirée',
          bgColor: themeComplement.palette.notifications.success.color,
        });
        dispatch({
          type: 'DETAIL_FULFILLED',
          collection: 'programmes',
          payload: response.data,
        });
        // if (cb) cb(response.data);
      })
      .catch((err) => {
        axiosErrorHandler(dispatch, err)
      });
  }
}
