/**
 * Collections a gérer de manière générique
 */
export default [
  'users',
  'roles',
  'rights',
  'programmes',
  'lots',
  'ressources'
]
