import {themeComplement} from './theme';

export default theme => ({
  drawerPaper: {
    position: 'fixed',
    height: '100vh',
    zIndex: 1200,
    width: themeComplement.palette.leftBarDefaultWidth,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: themeComplement.palette.tercerary,
    overflowX: 'hidden'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  iconButton: {
    color: themeComplement.palette.textColorLight
  },
  copyrightContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  copyright: {
    transition: 'all 0.2s ease-out'
  },
  copyrightSimple: {
    transition: 'all 0.2s ease-out'
  },
  logo: {
    display: 'block',
    position: 'absolute',
    top: '-70px',
    left: (themeComplement.palette.leftBarDefaultWidth / 2) - (95 / 2) + 'px' // Pour centrer l'élément en absolute, on divise par 2 la largeur de la colonne puis on retranche la moitié de la largeur de l'image
  },
  logoSimple: {
    marginBottom: '0.5em',
    display: 'block',
    height: '40px',
    width: 'auto',
    position: 'absolute',
    top: '-55px',
    left: '0.7em'
  }
})
