import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, Slide} from '@material-ui/core';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Modal extends Component {
  state = {
    open: false
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {...prevState};
    if (nextProps.openModal !== prevState.open) {
      newState = {
        ...newState,
        open: nextProps.openModal
      }
    }

    return newState;
  }

  /**
   * Pour valider le formulaire avec Enter
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  onKeyPress(e) {
    if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') { // Différents modes de détection pour prendre en charge l'ensemble des navigateurs
      this.props.onSubmitHandler()
    }
  }

  render() {
    const {onCloseHandler, onSubmitHandler} = this.props;

    return (
      <Dialog
        onKeyPress={this.onKeyPress.bind(this)}
        open={this.state.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCloseHandler}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={this.props.fullWidth}
        maxWidth={this.props.maxWidth}
      >
        {this.props.children}
        <DialogActions>
          <Button onClick={onCloseHandler}>
            Annuler
          </Button>
          <Button onClick={onSubmitHandler} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

Modal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string
};

export default Modal
