import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import {Switch, withRouter} from 'react-router-dom';
import Notification from '../../common/App/Notification';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {withStyles} from "@material-ui/core/styles/index";
import AppClientCss from "./css/AppClientCss";

import {Route} from 'react-router';
import Error404 from '../../common/ErrorsPages/Error404';
import {routes} from './routes/routes';

const theme = createMuiTheme({
  palette: {
    primary: {main: '#cd1861'},
    secondary: {main: '#11cb5f'},
  }
});

class AppClient extends Component {
  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {

    return null;
  }

  /**
   * Fonction de rendu final du composant
   */
  render() {
    const {classes} = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Grid
          container
          className={classes.mainContainer}
        >
          <Switch>
            {routes.map(r => <Route path={r.path} exact={r.exact} key={r.path} component={r.component}/>)}
            <Route component={Error404}/>
          </Switch>
          <Notification/>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

AppClient = withStyles(AppClientCss)(AppClient);
AppClient = withRouter(AppClient);

export default AppClient
