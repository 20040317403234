import {axiosClient} from '../../../../axios';
import {addNotification, axiosErrorHandler} from '../../../../reducers/notificationReducer';
import {themeComplement} from "../../AppAdmin/css/theme";

export function requestNewPassword(dispatch, user_uuid, cbOk) {
  axiosClient.post('users/'+user_uuid+'/password/new')
    .then((response) => {
      addNotification(dispatch, {
        message: 'Mot de passe modifé avec succés, mail envoyé.',
        bgColor: themeComplement.palette.notifications.success.color
      });
      if (cbOk) cbOk();
    })
    .catch((err) => {
      axiosErrorHandler(dispatch, err)
    })
}
