import React, {Component} from 'react';
// import RouterConfig from '../../../router/config';

export default class Programmes extends Component {
  render() {
    return (
      <div>Liste des programmes sur Wordpress</div>
    );
  }
}
