import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import EmptyDocumentsIcon from '@material-ui/icons/AddToPhotos';
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BlockIcon from "@material-ui/icons/RemoveCircle";
import React, {Component} from 'react';
import Dropzone from "react-dropzone";
import connect from "react-redux/es/connect/connect";
import {collectionActions} from "../../../reducers/collectionsReducer";
import {importDocuments, moveDocumentFirst} from "../Document/actions/DocumentsActions";
import CarrouselCss from './css/CarrouselCss';

class DetailGeneralCarrousel extends Component {
  dropzoneRef = {};
  mimeTypeConfig = [
    'image/png',
    'image/jpg',
    'image/gif',
    'image/jpeg',
  ];

  state = {
    openModal: false,
    selectedRessource: null,
  };

  /**
   * Gère l'appel de la modale en mode suppression
   * @param ressource
   */
  onClickDeleteIconHandler(ressource) {
    this.setState({
      openModal: true,
      selectedRessource: ressource,
    })
  }

  /**
   * Gère le passage de l'image en première position
   * @param ressource
   */
  onClickFirstImageIconHandler(ressource) {
    const {dispatch} = this.props;

    console.log('ressource', ressource);
    moveDocumentFirst(dispatch, ressource.uuid, {type: 'carrousel', item_uuid: ressource.item_uuid});

    // collectionActions(dispatch, 'ressources', 'INDEX', {params: {item_uuid: ressource.item_uuid}})
  }

  /**
   * Gère la validation de la suppression du document
   */
  onClickDeleteHandler() {
    const {dispatch} = this.props;
    let {selectedRessource} = this.state;

    collectionActions(dispatch, 'ressources', 'DELETE', selectedRessource, () => {
      this.setState({
        openModal: false,
        selectedResource: null
      });

      collectionActions(dispatch, 'ressources', 'INDEX', {params: {item_uuid: selectedRessource.item_uuid}})
    });
  }

  importDocumentsCallback(files) {
    const {dispatch, programmesStore} = this.props;
    let data = new FormData();

    files.forEach(file => {
      data.append('files[]', file);
    });

    data.append('item_uuid', programmesStore.detail.uuid);
    data.append('item_type', 'programme');
    data.append('ressource_type', 'carrousel');

    importDocuments(dispatch, data)
  }

  /**
   * Génère la modale de suppression de la ressource
   * @returns {*}
   */
  getModal() {
    const {openModal, selectedRessource} = this.state,
      {classes} = this.props;

    if (selectedRessource) {
      let modalTitle = 'Suppression du document',
        documentTitle = selectedRessource.libelle ? selectedRessource.libelle : selectedRessource.file.original_name,
        callback = this.onClickDeleteHandler.bind(this),
        content = <DialogContent>
          <Typography>Vous êtes sur le point de supprimer l’image du carrousel :</Typography>
          <Typography className={classes.deleteFileTitle}>- {documentTitle}</Typography>
        </DialogContent>;

      return (
        <Dialog
          open={openModal}
          onClose={() => this.setState({openModal: false, selectedRessource: null})}
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            {modalTitle}
          </DialogTitle>
          {content}
          <DialogActions>
            <Button onClick={() => this.setState({openModal: false})}>
              Fermer
            </Button>
            <Button onClick={callback} color="primary">
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
  }

  /**
   * Génère l'affichage des vignettes
   * @returns {*}
   */
  getCarrouselPictures() {
    const {classes, ressourcesStore} = this.props;

    if (!ressourcesStore.list) return null;

    let result = [];

    result.push(
      <Grid item xs='auto' key="bouton_ajout">
        <Button
          variant="contained"
          title="Ajouter des images au carrousel"
          className={classes.imageCarrouselCardAddicon}
          onClick={() => {
            this.dropzoneRef.open()
          }}
        >
          <Add/>
        </Button>
      </Grid>
    );

    result.push(ressourcesStore.list.map(ressource => {
      let image = null;

      if (ressource.ressource_type === 'carrousel') {
        let titre = ressource.file.original_name;

        if (ressource.libelle) titre = ressource.libelle;

        image = <Grid item xs={2} key={ressource.uuid}>
          <div className={classes.imageCarrouselCardContainer}>
            <div className={classes.imageCarrouselCardHeader}>
              {titre.substring(0, 15) + (titre.length > 15 ? '...' : '')}
              <IconButton
                aria-label="Supprimer l'image du carrousel"
                title="Supprimer l'image du carrousel"
                className={classes.imageCarrouselCardDeleteIcon}
                onClick={this.onClickDeleteIconHandler.bind(this, ressource)}
              >
                <DeleteIcon/>
              </IconButton>
              <IconButton
                aria-label="Mettre l'image en première position"
                title="Mettre l'image en première position"
                className={classes.imageCarrouselCardFirstImageIcon}
                onClick={this.onClickFirstImageIconHandler.bind(this, ressource)}
                >
                <ArrowBackIcon/>
                </IconButton>
            </div>
            <img
              src={ressource.file.cellar_path}
              alt={ressource.libelle}
              className={classes.imageCarrouselCard}
              onClick={() => window.open(ressource.file.cellar_path)}
            />
          </div>
        </Grid>
      }

      return image
    }));

    return result
  }

  isDisplayed() {
    let {classes, ressourcesStore} = this.props,
      result = classes.AcceptPanelDisplay;

    if (ressourcesStore.list && ressourcesStore.list.length > 0) {
      let test = ressourcesStore.list.find(ressource => {
        return ressource.ressource_type === 'carrousel'
      });

      if (test !== undefined) result = classes.AcceptPanelDisplayNone;
    }

    return result
  }

  minHeight() {
    let {classes, ressourcesStore} = this.props,
      result = '';

    if (ressourcesStore.list && ressourcesStore.list.length > 0) {
      let test = ressourcesStore.list.find(ressource => {
        return ressource.ressource_type === 'carrousel'
      });

      if (test !== undefined) result = classes.imageCarrouselContainerMinHeight;
    }

    return result
  }

  /**
   * Rendu global
   * @returns {*}
   */
  render() {
    const {classes, ressourcesStore} = this.props;

    if (ressourcesStore.fetching) return <CircularProgress/>;

    return (
      <Dropzone
        ref={(node) => {
          this.dropzoneRef = node;
        }}
        multiple={true}
        disableClick={true}
        disablePreview={true}
        accept={this.mimeTypeConfig}
        className={classes.CarrouselDropZone}
        acceptClassName={classes.CarrouselDropZoneAccept}
        rejectClassName={classes.CarrouselDropZoneReject}
        activeClassName="dropActive"
        onDropAccepted={this.importDocumentsCallback.bind(this)}
      >
        <FormHelperText className={classes.CarrouselLabel}>Carrousel d’images</FormHelperText>
        <Grid item xs={12}>
          <Grid container spacing={24} className={classes.imageCarrouselContainer + ' ' + this.minHeight()}>
            <Grid item xs={12} className={classes.CarrouselDropZoneAcceptPanel + ' ' + this.isDisplayed()}>
              <EmptyDocumentsIcon className={classes.CarrouselDropZoneAcceptPanelIcon}/>
              <Typography className={classes.CarrouselDropZoneAcceptPanelText}>Déposez vos fichiers ici afin de
                les ajouter.</Typography>
            </Grid>
            <Grid item xs={12} className={classes.CarrouselDropZoneRejectPanel}>
              <BlockIcon className={classes.CarrouselDropZoneRejectPanelIcon}/>
              <Typography className={classes.CarrouselDropZoneRejectPanelText}>Impossible d’importer ce type de
                document.</Typography>
            </Grid>
            {this.getCarrouselPictures()}
          </Grid>
          {this.getModal()}
        </Grid>
      </Dropzone>
    )
  }
}

DetailGeneralCarrousel = withStyles(CarrouselCss)(DetailGeneralCarrousel);

DetailGeneralCarrousel = connect((store) => {
  return {
    programmesStore: store.collections.programmes,
    ressourcesStore: store.collections.ressources,
  }
})(DetailGeneralCarrousel);

export default DetailGeneralCarrousel
