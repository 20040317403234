import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {
  Grid,
  MenuItem,
  Typography,
  Button,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {Person, Fingerprint} from '@material-ui/icons';
import {loadCollectionAttribute} from "../../../reducers/collectionsReducer";
import TextInput from '../../common/Components/TextInput';
import Modal from '../../common/Components/Modal';
import UserCss from './css/UserCss';
import CollectionCrud from '../../common/Components/CollectionCrud';
import {requestNewPassword} from './actions/UserActions';

class User extends Component {

  state = {
    openChPassModal: false,
    selectedUser: null,
  }

  /**
   * Lors du montage du composant
   */
  componentDidMount() {
    loadCollectionAttribute(this.props.dispatch, 'list', 'roles', this.props.rolesStore)
  }

  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle() {
    return <div><Person/> <span>{'Suppression de l\'utilisateur'}</span></div>;
  }

  getDeleteModalContent() {
    return <Typography>{'Êtes vous sûr de vouloir supprimer cet utilisateur ?'}</Typography>;
  }

  getCreateUpdateModalTitle(user) {
    return <div><Person/>
      <span>{!!user ? 'Édition de l\'utilisateur : ' + user.firstname + ' ' + user.lastname : 'Création d\'un nouvel utilisateur'}</span>
    </div>;
  }

  /**
   * Construction de la liste des rôles
   * @returns {*}
   */
  getRoles() {
    if (!this.props.rolesStore.list) return [];

    return this.props.rolesStore.list.map(role => {
      return (
        <MenuItem value={role.uuid} key={role.uuid}>
          {role.libelle}
        </MenuItem>
      );
    })
  }

  getChPassBtn(user) {
    if (user && user.role && user.role.slug === 'pro') {
      return (
        <Button
          variant="contained"
          color="primary"
          aria-label="change-password"
          disabled={this.state.userModified}
          onClick={() => {
            this.setState({
              openChPassModal: true,
              selectedUser: user,
            });
          }}
          size="small"
        >
          Envoyer nouveau mot de passe
        </Button>
      );
    } else {
      return null;
    }
  }

  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(user, usersStore, onChangeHandlerOrg) {
    let {firstname, lastname, societe, email, role_uuid} = user;
    const {classes} = this.props;

    function onChangeHandler(target, event) {
      this.setState({
        userModified: true,
      });
      onChangeHandlerOrg(target, event);
    }

    return (
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <TextInput
            id="firstname"
            label="Prénom"
            value={firstname}
            margin="normal"
            collectionStore={usersStore}
            name='firstname'
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="lastname"
            label="Nom"
            value={lastname}
            margin="normal"
            collectionStore={usersStore}
            name='lastname'
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="societe"
            label="Société"
            value={societe}
            margin="normal"
            collectionStore={usersStore}
            name='societe'
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="email"
            label="Email"
            value={email}
            margin="normal"
            collectionStore={usersStore}
            name='email'
            onChangeHandler={onChangeHandler.bind(this)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="role_uuid"
            label="Rôle"
            value={role_uuid}
            margin="normal"
            collectionStore={usersStore}
            name='role_uuid'
            onChangeHandler={onChangeHandler.bind(this)}
            className={classes.selectContainer}
            select
          >
            {this.getRoles()}
          </TextInput>
        </Grid>
        <Grid item xs={6} className={classes.buttonContainer}>
          {this.getChPassBtn(user)}
        </Grid>
      </Grid>
    );
  }

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    return [
      {
        datakey: "firstname",
        title: "Prénom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "lastname",
        title: "Nom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "societe",
        title: "Société",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "email",
        title: "Email",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "role.libelle",
        title: "Rôle",
        sortable: true,
        searchable: true,
      }
    ];
  }


  /**
   * fermeture de la modal de changement de password
   * @return {[type]} [description]
   */
  onCloseChPassModalHandler() {
    this.setState({openChPassModal: false});
  }


  confirmChPass() {
    requestNewPassword(this.props.dispatch, this.state.selectedUser.uuid, () => {
      this.setState({openChPassModal: false});
    });
  }

  /**
   * Retourne la modal pour changer de mot de passe
   * @return {[type]} [description]
   */
  getModalChangePassword() {
    const {openChPassModal} = this.state

    if (!openChPassModal) return null;

    return (
      <Modal
        openModal={openChPassModal}
        onCloseHandler={this.onCloseChPassModalHandler.bind(this)}
        onSubmitHandler={this.confirmChPass.bind(this)}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <Fingerprint/> <span>Changer le mot de passe</span>
        </DialogTitle>
        <DialogContent key="content">
          <Typography>
            Voulez-vous changer le mot de passe de cet utilisateur ? Un mail avec
            les nouveaux accès va lui être envoyé.
          </Typography>
        </DialogContent>
      </Modal>
    );
  }


  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes} = this.props;
    return (
      <Grid container spacing={24} className={classes.container}>
        <CollectionCrud
          collectionName={'users'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "lastname",
            rowsPerPageOptions: [10, 20, 50],
          }}
          cellsConfig={this.getCellsConfig()}
          onModalOpen={() => this.setState({userModified: false})}
        />
        {this.getModalChangePassword()}
      </Grid>
    );
  }
}

User = withStyles(UserCss)(User);

User = connect((store) => {
  return {
    rolesStore: store.collections.roles
  }
})(User);

export default User
