import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ProgrammeCss from './css/ProgrammeCss';

class DetailLotsModal extends Component {
  /**
   * Détermine la traduction du libellé du statut
   * @param statut
   * @returns {*}
   */
  getStatusTranslation(statut) {
    let result = null;

    switch (statut) {
      case 'disponible':
        result = 'Disponible';
        break;
      case 'option':
        result = 'Option';
        break;
      case 'en_cours_signature':
        result = 'En cours de signature';
        break;
      case 'reserve':
        result = 'Réservé';
        break;
      default:
        break;
    }

    return result
  }

  /**
   * Détermine l'unité à affficher pour la donnée
   * @param unite
   * @returns {string}
   */
  unite(unite) {
    let result = '';

    switch (unite) {
      case 'surface':
        result = ' m²';
        break;
      case 'montant':
        result = ' €';
        break;
      default:
        break;
    }

    return result
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    let {closeModal, lot, openModal, classes} = this.props;
    const lotStatus = [
      {label: 'Statut', data: lot.statut,},
    ], lotContent = [
      {label: 'Type bien', data: lot.type_bien,},
      {label: 'Type', data: lot.type,},
      {label: 'Pièces', data: lot.pieces,},
      {label: 'Viabilisé', data: (lot.viabilise ? 'Oui' : '')},
      {label: 'Niveau', data: lot.niveau,},
      {label: 'Surface habitable', data: lot.surface_habitable, unite: 'surface',},
      {label: 'Surface utile', data: lot.surface_utile, unite: 'surface',},
      {label: 'Cuisine', data: lot.cuisine,},
      {label: 'Exposition', data: lot.exposition,},
      {label: 'Jardin', data: lot.jardin, unite: 'surface',},
      {label: 'Balcon', data: lot.balcon, unite: 'surface',},
      {label: 'Terrasse', data: lot.terrasse, unite: 'surface',},
      {label: 'Loggia', data: lot.loggia, unite: 'surface',},

      {label: 'Num. local vélo', data: lot.num_local_velo,},
      {label: 'Surface local vélo', data: lot.surface_local_velo, unite: 'surface',},
      {label: 'Num. carport', data: lot.num_carport,},
      {label: 'Surface carpot', data: lot.surface_carport, unite: 'surface',},
      {label: 'Num. garage', data: lot.num_garage,},
      {label: 'Surface garage', data: lot.surface_garage, unite: 'surface',},
      {label: 'Num. parking', data: lot.num_parking,},
      {label: 'Surface parking', data: lot.surface_parking, unite: 'surface',},

      {label: 'Tarif TTC 20', data: lot.tarif_ttc20, unite: 'montant',},
      {label: 'Tarif TTC 10', data: lot.tarif_ttc10, unite: 'montant',},
      {label: 'Loyer PLS', data: lot.loyer_pls, unite: 'montant',},
      {label: 'Loyer Pinel', data: lot.loyer_pinel, unite: 'montant',},
      {label: 'Loyer conseillé', data: lot.loyer_conseille, unite: 'montant',},
      {label: 'Charges propriétaires', data: lot.charges_proprietaires, unite: 'montant',},
      {label: 'Charges locataires', data: lot.charges_locataires, unite: 'montant',},
      {label: 'Charges totales', data: lot.charges_totales, unite: 'montant',},
    ];

    return (
      <Dialog
        open={openModal}
        onClose={closeModal}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          Détail du lot : {lot.num}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={24}>

            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow key={lotStatus[0].label}>
                    <TableCell component="th" scope="row">
                      {lotStatus[0].label}
                    </TableCell>
                    <TableCell align="right" className={classes.dialogContentData}>
                      {this.getStatusTranslation(lotStatus[0].data)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12}>
              <Table className={classes.table}>
                <TableBody>
                  {lotContent.map(content => {
                    return (
                      <TableRow key={content.label}>
                        <TableCell component="th" scope="row">
                          {content.label}
                        </TableCell>
                        <TableCell align="right" className={classes.dialogContentData}>
                          {content.data ? content.data + this.unite(content.unite) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DetailLotsModal = withStyles(ProgrammeCss)(DetailLotsModal);

DetailLotsModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  lot: PropTypes.object.isRequired
};

export default DetailLotsModal
