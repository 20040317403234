import Programme from "../../Programme";
import User from '../../User';
import Role from '../../Role';

import HomeAdmin from '../../Home';
import AuthAdmin from '../../Auth';

export const routes = [
  {path: '/users', component: User, exact: true, public: false, right: 'admin-crud-users'},
  {path: '/roles', component: Role, exact: true, public: false, right: 'admin-crud-roles'},
  {path: '/programmes', component: Programme, exact: false, public: false, right: 'admin-crud-programmes'},
  {path: '/auth', component: AuthAdmin, exact: false, public: true, noContainer: true},
  {path: '/', component: HomeAdmin, exact: true, public: false, right: 'admin-home'},

];
