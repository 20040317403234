import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import {Beenhere} from '@material-ui/icons';
import ProgrammeCss from './css/ProgrammeCss';
import CollectionCrud from '../../common/Components/CollectionCrud';
import EditIcon from '@material-ui/icons/Edit';
import {buildRoute} from '../../../router/Tools'
import Modal from '../../common/Components/Modal';
import {createProgramme} from './actions/ProgrammesActions';



class Collection extends Component {
  state = {
    openModalCreateProgramme: false,
    new_programme: '',
  };

  /**
   * Title de la modale de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle() {
    return <div><Beenhere/> <span>{'Suppression d’un programme'}</span></div>;
  }

  /**
   * Contenu de la modale de suppression
   * @returns {*}
   */
  getDeleteModalContent() {
    return <Typography>{'Êtes vous sûr de vouloir supprimer ce programme ?'}</Typography>;
  }

  onClickAddButton() {
    this.setState({openModalCreateProgramme: true})
  }

  /**
   * fermeture de la modale de creation de programme
   * @return {[type]} [description]
   */
  onCloseModalCreateProgrammeHandler() {
    this.setState({openModalCreateProgramme: false})
  }

  onSubmitCreateProgrammeHandler() {
    createProgramme(this.props.dispatch, this.state, this.props.history);
    this.onCloseModalCreateProgrammeHandler();
  }

  /**
   * Handler de changement sur les champs d'un nouveau programme
   */
   handleChange(event) {
     let {value, name} = event.target;
     this.setState({
       [name]: value,
     });
   };

  /**
   * Génère le bouton d'ajout de programme
   * @returns {*}
   */
  getAddButton() {
    const {classes} = this.props;
    return <Button
      variant="fab"
      color="primary"
      aria-label="add"
      mini
      onClick={this.onClickAddButton.bind(this)}
      className={classes.addButton}
      title="Ajouter"
    >
      <Add style={{fontSize: 32}}/>
    </Button>
  }

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    return [
      {
        datakey: "nom",
        title: "Nom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "created_at",
        title: "Date de création",
        sortable: true,
        searchable: false,
      },
    ];
  }

  /**
   * Retourne le bouton d'édition
   * @param  {[type]} programme [description]
   * @return {[type]}        [description]
   */
  getEditBtn(programme) {
    let {classes} = this.props;
    return (
      <IconButton
        key="detail"
        aria-label="Édition"
        color="primary"
        onClick={() => {
          this.props.history.push(buildRoute('/programmes/:uuid/general', {uuid: programme.uuid}))
        }}
        title="Édition"
        className={classes.editButton}
      >
        <EditIcon/>
      </IconButton>
    );
  }

  /**
   * Retourne la modal pour créer un programme
   * @return {[type]} [description]
   */
  getModalCreateProgramme() {
    const {openModalCreateProgramme, new_programme} = this.state
    let {classes} = this.props;

    return (
      <Modal
        openModal={openModalCreateProgramme}
        onCloseHandler={this.onCloseModalCreateProgrammeHandler.bind(this)}
        onSubmitHandler={this.onSubmitCreateProgrammeHandler.bind(this)}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle>
          <EditIcon/> <span>Créer un nouveau programme</span>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <TextField
                name="new_programme"
                label="Nom du programme"
                className={classes.textField}
                value={new_programme}
                onChange={this.handleChange.bind(this)}
                type={"text"}
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Modal>
    );
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes} = this.props;
    return (
      <Grid container spacing={24} className={classes.container}>
        <CollectionCrud
          collectionName={'programmes'}
          showBtnDelete
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          dataTableExtraNodes={[{
            element: this.getAddButton(),
            position: 'top-right'
          }]}
          additionnalControllers={[
            this.getEditBtn.bind(this)
          ]}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "created_at",
            sortType: "asc",
            rowsPerPageOptions: [10, 20, 50],
          }}
          cellsConfig={this.getCellsConfig()}
        />
        {this.getModalCreateProgramme()}
      </Grid>
    );
  }
}

Collection = withStyles(ProgrammeCss)(Collection);

Collection = connect((store) => {
  return {
    programmesStore: store.collections.programmes
  }
})(Collection);

export default Collection
