import {axiosClient} from '../../../../axios';
import {addNotification, axiosErrorHandler} from '../../../../reducers/notificationReducer';
import {themeComplement} from "../../AppAdmin/css/theme";

/**
 * Permet d'importer les lots via le fichier Excel
 * @param  {[type]} dispatch       [description]
 * @param  {[type]} programme_uuid [description]
 * @param  {[type]} data           [description]
 * @return {[type]}                [description]
 */
export function importLots(dispatch, programme_uuid, data) {
  axiosClient.post('programmes/'+programme_uuid+'/import', data)
    .then((response) => {
      addNotification(dispatch, {
        message: 'Lots importés avec succès (' + response.data.length + ' lots)',
        bgColor: themeComplement.palette.notifications.success.color
      })
      dispatch({
        type: 'LIST_FULFILLED',
        collection: 'lots',
        payload: response.data,
      });
    })
    .catch((err) => {
      axiosErrorHandler(dispatch, err)
    })
}
