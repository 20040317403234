import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import LeftMenuButtonCss from './css/LeftMenuButtonCss';
import {withStyles} from '@material-ui/core/styles/index';
import PropTypes from 'prop-types';
import {isMatchingRoute} from '../../../router/Tools';
import {routes} from './routes/routes';

class LeftMenuButton extends Component {
  state = {
    currentRoute: false
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    let oneMatching = false;
    nextProps.button.matchingPath.forEach((path) => {
      if (isMatchingRoute(path, nextProps.location.pathname) && !oneMatching) {
        oneMatching = true
      }
    });

    if (prevState.currentRoute !== oneMatching) {
      return {
        currentRoute: oneMatching
      };
    } else return null;
  }

  onClickTodoHandler() {
    this.props.history.push(this.props.button.link)
  }

  hasRight() {
    const {currentUser} = this.props;

    let route = routes.find(route => isMatchingRoute(route.path, this.props.button.link));

    return !!route && !!currentUser && (route.public || currentUser.rights.includes(route.right))
  }

  render() {
    const {classes} = this.props,
      currentRoute = this.state.currentRoute;

    if (this.hasRight()) {
      return (
        <ListItem
          button
          onClick={this.onClickTodoHandler.bind(this)}
          title={this.props.button.label}
          classes={{root: currentRoute ? classes.listItemCurrent : classes.listItem}}
        >
          <ListItemIcon
            classes={{root: currentRoute ? classes.listItemIconCurrent : classes.listItemIcon}}
          >
            {this.props.button.icon}
          </ListItemIcon>
          <ListItemText
            primary={this.props.button.label}
            classes={{primary: currentRoute ? classes.listItemTextCurrent : classes.listItemText}}
          />
        </ListItem>
      );
    } else return null
  }
}

LeftMenuButton.propTypes = {
  button: PropTypes.object.isRequired,
};

LeftMenuButton = withStyles(LeftMenuButtonCss, {withTheme: true})(LeftMenuButton);
LeftMenuButton = withRouter(LeftMenuButton);

LeftMenuButton = connect((store) => {
  return {
    location: store.router.location,
    currentUser: store.auth.user
  }
})(LeftMenuButton);

export default LeftMenuButton
