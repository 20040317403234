import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core/styles/index";
import LeftMenuCss from "./css/LeftMenuCss";
import classNames from 'classnames';
import {Divider, Drawer, List, Typography} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Beenhere from '@material-ui/icons/Beenhere';
import StyleIcon from '@material-ui/icons/Style';
import PeopleIcon from '@material-ui/icons/People';
import DefaultLogo from '../../../assets/img/default-logo-63px.png';
import LogoArbre from '../../../assets/img/logo-arbre-63px.png';

import LeftMenuButton from "./LeftMenuButton";

class LeftMenu extends Component {
  state = {
    open: true,
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {
        ...prevState,
        open: nextProps.open
      };
    }

    return null;
  }

  /**
   * Fonction de rendu final du composant
   */
  render() {
    const {classes, noContainer, user} = this.props,
      buttons = {
        dashboard: {
          label: "Dashboard",
          link: "/",
          matchingPath: ["/"],
          icon: <DashboardIcon/>
        },
        programmes: {
          label: "Programmes",
          link: "/programmes",
          matchingPath: [
            "/programmes",
            "/programmes/:uuid",
            "/programmes/:uuid/general",
            "/programmes/:uuid/documents",
            "/programmes/:uuid/lots",
          ],
          icon: <Beenhere/>
        },
        users: {
          label: "Utilisateurs",
          link: "/users",
          matchingPath: ["/users"],
          icon: <PeopleIcon/>
        },
        roles: {
          label: "Rôles",
          link: "/roles",
          matchingPath: ["/roles"],
          icon: <StyleIcon/>
        }
      };

    if (!user || noContainer) return null;

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
        }}
        open={this.state.open}
      >
        <div className={classes.toolbar}>
          <IconButton
            onClick={this.props.handleLeftMenuToggle}
            className={classes.iconButton}
            title="Replier le menu"
          >
            <ChevronLeftIcon/>
          </IconButton>
        </div>
        <Divider/>
        <List>
          <div>
            <LeftMenuButton
              button={buttons.dashboard}
            />
            <LeftMenuButton
              button={buttons.programmes}
            />
          </div>
        </List>
        <Divider/>
        <List>
          <div>
          <LeftMenuButton
            button={buttons.users}
          />
          <LeftMenuButton
            button={buttons.roles}
          />
          </div>
        </List>
        <Divider/>
        <div className={classes.copyrightContainer}>
          <Typography
            title="© 2018 Oniti.fr"
            variant="title"
            color="inherit"
            className={classes.copyright}
            style={{
              opacity: this.state.open ? '1' : '0'
            }}
          >
            <a href="https://www.oniti.fr">
              <img
                className={classes.logo}
                src={DefaultLogo}
                alt="© 2018 Oniti.fr"
              />
            </a>
          </Typography>
          <Typography
            title="© 2018 Oniti.fr"
            variant="title"
            color="inherit"
            className={classes.copyrightSimple}
            style={{
              opacity: this.state.open ? '0' : '1'
            }}
          >
            <a href="https://www.oniti.fr">
              <img
                className={classes.logoSimple}
                src={LogoArbre}
                alt="© 2018 Oniti.fr"
              />
            </a>
          </Typography>
        </div>
      </Drawer>
    );
  }
}

LeftMenu = withStyles(LeftMenuCss, {withTheme: true})(LeftMenu);
LeftMenu = connect((store) => {
  return {
    user: store.auth.user
  }
})(LeftMenu);

export default LeftMenu
