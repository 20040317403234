import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  Grid,
  CircularProgress,
  Switch,
} from '@material-ui/core';
import ProgrammeCss from './css/ProgrammeCss';
import {Cell, DataTable} from '@oniti/datatable-material';
import connect from "react-redux/es/connect/connect";
import {collectionActions} from "../../../reducers/collectionsReducer";
import {userAllowOrForbid} from './actions/ProgrammesActions';


class DetailAcces extends Component {

  componentDidMount() {
    collectionActions(this.props.dispatch, 'users', 'INDEX', {});
  }

  /**
   * Handler sur les changement des Checkboxs
   * @param  {[type]} uuid [description]
   * @return {[type]}      [description]
   */
  handleChangeCheckBox(user_uuid, event) {
    const allow = event.target.checked;
    userAllowOrForbid(
      this.props.dispatch,
      this.props.programmesStore.detail.uuid,
      user_uuid,
      allow
    );
  }

  /**
   * Rendu de la cellule des actions
   * @param  {[type]} lot [description]
   * @return {[type]}       [description]
   */
  getAction(user) {
    if (!this.props.programmesStore || !this.props.programmesStore.detail) {
      return null;
    }

    let forbiddenUsers = this.props.programmesStore.detail.forbidden_users;
    let forbiddenUser = forbiddenUsers.find(u => u.uuid === user.uuid);

    return (
      <Switch
        checked={!forbiddenUser}
        onChange={this.handleChangeCheckBox.bind(this, user.uuid)}
        value={user.uuid}
        color="primary"
      />
    );
  }

  /**
   * Retourne les Cells du datatable
   * @return {[type]} [description]
   */
  getCells() {
    const cellsConfig = [
      {
        datakey: "firstname",
        title: "Prénom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "lastname",
        title: "Nom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "societe",
        title: "Société",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "email",
        title: "Email",
        sortable: true,
        searchable: true,
      },
    ];
    let cells = cellsConfig.map((conf, index) => <Cell key={index} {...conf} />);

    cells.push(
      <Cell
        key="action"
        format={this.getAction.bind(this)}
        sortable={false}
        title="Autorisé"
      />
    );

    return cells;
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    let {classes, usersStore} = this.props;
    if (!usersStore.list) return <CircularProgress/>;

    return (
      <Grid
        container
        spacing={24}
        className={classes.programmeContainer}
      >
        <Grid item xs={12}>
          <DataTable
            datas={usersStore.list}
            className={classes.dataTableAcces}
            showPagination={true}
            showSearch={true}
            defaultSort={"lastname"}
            rowsPerPageOptions={[10, 20, 50]}
            cancelUpdateCheck={true}
          >
            {this.getCells()}
          </DataTable>
        </Grid>
      </Grid>
    )
  }
}

DetailAcces = withStyles(ProgrammeCss)(DetailAcces);

DetailAcces = connect((store) => {
  return {
    usersStore: store.collections.users,
    programmesStore: store.collections.programmes,
  }
})(DetailAcces);

export default DetailAcces
