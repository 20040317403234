import {AppBar, Button, Grid, Tab, Tabs, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import Beenhere from '@material-ui/icons/Beenhere';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import SwipeableViews from 'react-swipeable-views';
import {collectionActions} from "../../../reducers/collectionsReducer";
import {buildRoute} from "../../../router/Tools";
import ProgrammeCss from './css/ProgrammeCss';
import DetailGeneral from "./DetailGeneral";
import Documents from "../Document";
import CloudDownload from '@material-ui/icons/CloudDownload';
import DetailLots from "./DetailLots";
import DetailAcces from "./DetailAcces";

function TabContainer({children, dir, tabNumber, selectedTab}) {
  return (
    <Typography component="div" dir={dir} style={{padding: 8 * 2, textAlign: 'center', display: tabNumber !== selectedTab ? 'none' : 'block'}}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const initialState = {
  uuid: null,
  nom: '',
  visible: false,
  description: '',
  avancement: 'projet',
  bbc: true,
  rt2012: true,
  re2020: true,
};

const correspondanceOnglets = {
  general: 0,
  documents: 1,
  lots: 2,
  acces: 3,
};

class Detail extends Component {
  state = {
    tabValue: correspondanceOnglets.general,
    programme: initialState,
  };

  componentDidMount() {
    let programmeUuid = this.props.match.params.programmeUuid, // on récupère l'uuid fourni dans l'url
      onglet = this.props.match.params.onglet,
      {dispatch} = this.props;

    if (onglet) {
      let ongletCourant = null;
      switch (onglet) {
        case 'documents':
          ongletCourant = correspondanceOnglets.documents;
          break;
        case 'lots':
          ongletCourant = correspondanceOnglets.lots;
          break;
        case 'acces':
          ongletCourant = correspondanceOnglets.acces;
          break;
        default:
          ongletCourant = correspondanceOnglets.general;
      }

      this.setState({
        tabValue: ongletCourant
      })
    }

    collectionActions(dispatch, 'programmes', 'SHOW', {uuid: programmeUuid});
    collectionActions(dispatch, 'lots', 'INDEX', {params: {programme_uuid: programmeUuid}});
    collectionActions(dispatch, 'ressources', 'INDEX', {params: {item_uuid: programmeUuid}})
  }

  static getDerivedStateFromProps(props, state) {
    let result = null;

    if (props.programmesStore.detail && props.programmesStore.detail.uuid !== state.programme.uuid) {
      result = {
        programme: props.programmesStore.detail
      };
    }

    return result
  }

  componentWillUnmount() {
    let {dispatch} = this.props;
    collectionActions(dispatch, 'programmes', 'RESET_DETAIL')
  }

  /**
   * Gestion de la modification des champs
   * @param name
   * @param event
   * @returns {Function}
   */
  onChangeHandler = (name, event) => {
    let {value} = event.target;

    this.setState({
      programme: {
        ...this.state.programme,
        [name]: value
      }
    });
  };

  /**
   * Gestion de la modification des champs "switch" on/off
   * @param name
   * @param event
   * @returns {Function}
   */
  onChangeSwitchHandler = (name, event) => {
    this.setState({
      programme: {
        ...this.state.programme,
        [name]: event.target.checked
      }
    });
  };

  onChangeSelectHandler = event => {
    this.setState({
      programme: {
        ...this.state.programme,
        [event.target.name]: event.target.value
      }
    })
  };

  /**
   * Gestion des onglets
   * @param event
   * @param value
   */
  handleChangeTab = (event, value) => {
    this.setState({tabValue: value}, () => {
      let labelOnglet = Object.keys(correspondanceOnglets).find(key => correspondanceOnglets[key] === value);

      this.props.history.push(
        buildRoute('/programmes/:uuid/' + labelOnglet, {uuid: this.props.match.params.programmeUuid})
      )
    });
  };

  handleChangeTabIndex = index => {
    this.setState({tabValue: index});
  };

  /**
   * Callback de soumission du formulaire "Programme"
   */
  onSubmithandler() {
    const {dispatch} = this.props;

    collectionActions(dispatch, 'programmes', !this.state.programme.uuid ? 'CREATE' : 'UPDATE', {
      ...this.state.programme
    });
  }

  getExportButton() {
    if (this.props.match.params.programmeUuid === 'nouveau') return null;

    return (
      <Button
        color='primary'
        title='Exporter le programme'
      >
        <CloudDownload/>
      </Button>
    )
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    let {classes, programmesStore} = this.props,
      {programme, tabValue} = this.state;

    return (
      <Grid container spacing={24}>
        <Grid item xs={12} className={classes.programmeTitleContainer}>
          <Typography
            variant="button"
            className={classes.programmeTypoContainer}
          >
            <Beenhere
              className={classes.programmeTitleIcon}/> {'Programme : ' + programme.nom}
          </Typography>
          {this.getExportButton()}
          <Button
            title='Retour à la liste des programmes'
            color='primary'
            className={classes.returnButton}
            onClick={() => this.props.history.push('/programmes')}
          >
            <ChevronLeft/> Retour
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.tabsContainer}>
          <AppBar position="static" color="default" className={classes.appBar}>
            <Tabs
              value={tabValue}
              onChange={this.handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Général"/>
              <Tab label="Documents"/>
              <Tab label="Lots"/>
              <Tab label="Accès"/>
            </Tabs>

          </AppBar>
        </Grid>
        <SwipeableViews
          axis={'x'}
          index={tabValue}
          onChangeIndex={this.handleChangeTabIndex}
          className={classes.tabContentContainer}
        >
          <TabContainer dir={'ltr'} tabNumber={correspondanceOnglets.general} selectedTab={tabValue}>
            <DetailGeneral
              programmesStore={programmesStore}
              onChangeHandler={this.onChangeHandler.bind(this)}
              onChangeSelectHandler={this.onChangeSelectHandler.bind(this)}
              onChangeSwitchHandler={this.onChangeSwitchHandler.bind(this)}
              onSubmithandler={this.onSubmithandler.bind(this)}
              programme={programme}
            />
          </TabContainer>
          <TabContainer dir={'ltr'} tabNumber={correspondanceOnglets.documents} selectedTab={tabValue}>
            <Documents
              itemUuid={programme.uuid}
              itemType='programme'
              display={['document']}
              showFavoriteOnCards={true}
            />
          </TabContainer>
          <TabContainer dir={'ltr'} tabNumber={correspondanceOnglets.lots} selectedTab={tabValue}>
            <DetailLots/>
          </TabContainer>
          <TabContainer dir={'ltr'} tabNumber={correspondanceOnglets.acces} selectedTab={tabValue}>
            <DetailAcces/>
          </TabContainer>
        </SwipeableViews>
      </Grid>
    );
  }
}

Detail = withStyles(ProgrammeCss)(Detail);

Detail = connect((store) => {
  return {
    programmesStore: store.collections.programmes
  }
})(Detail);

export default Detail
