import AppAdmin from '../components/admin/AppAdmin';
import AppClient from '../components/client/AppClient';

export const routes = [
  {
    path: '/', component: AppClient, exact: false, public: true, domains: [
      'sogimmo.fr',
      'www.sogimmo.fr',
      'sogimmo.oniti.pro'
    ]
  },
  {path: '/', component: AppAdmin, exact: false, public: true},
];
